import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./login/Login";
import SignUp from "./signup/Signup";
import React, { useState, useEffect, useRef } from "react";
import Header from "./headsidebar/Header";
import SideBar from "./headsidebar/SideBar";
import Staff from "./staff/Staff";
import Students from "./students/Students";
import Home from "./home/Home";
import Schools from "./schools/Schools";
import AddSchool from "./schools/AddSchool/AddSchool";
import Contactus from "./contactus/Contactus";
import ForgotPassword from "./forgotpassword/ForgotPassword";
import SchoolDetails from "./schools/SchoolDetails/SchoolDetails";
import VerifyEmailBlock from "./verifyemail/VerifyEmailBlock";
import VerifyEmailPage from "./verifyemail/VerifyEmailPage";
import AddStudent from "./students/AddStudent";
import AddStaff from "./staff/AddStaff";
import StaffDetails from "./staff/StaffDetails";
import { withRouter } from "react-router-dom";
import Badge from "./badges/Badge";
import AddBadge from "./badges/AddBadge";
import Devices from "./panels/Device";
import PanelAlerts from "./panels/PanelAlerts";
import SuperPanelAlerts from "./panels/SuperPanelAlerts";
import AddDevice from "./panels/AddDevice";
import AccountSettings from "./account/AccountSettings";
import SchoolSettings from "./schools/SchoolSettings";
import NotFoundPage from "./errorPages/NotFoundPage";
import BlockPage from "./errorPages/BlockPage";
import ResetPassword from "./resetpassword/ResetPassword";
import AddPlan from "./plan/AddPlan";
import Plan from "./plan/Plan";
import AssignStudents from "./staff/AssignStudents";
import { getAccessTokenInStore, getRoleInStore, getNotificationStatusInStore, removeApiLogInStore, removeRenewAccessTokenInProgress, getPriorityUserIdInStore, getPriorityNotificationStatusInStore } from "../utils/apiUtils";
import Feedbacklist from "./feedback/FeedbackList";
import {initGA,logPageView} from "../analytics"
import Privacy from "./PrivacySecurity/Privacy";
// export const history = createBrowserHistory();

const PrivateRoute = ({ component: Component, passProps, ...rest }) => {
	const token = getAccessTokenInStore();
	const role = getRoleInStore();
	const allowedComponentsForSuperAdmin = [
		AddSchool,
		SchoolDetails,
		Contactus,
		AccountSettings,
		VerifyEmailBlock,
		Schools,
		SuperPanelAlerts,
		AddPlan,
		Plan,
		Feedbacklist,
		Privacy
	];
	  
	const blockedComponentsForSchoolAdmin = [
		AddSchool,
		SchoolDetails,
		Schools,
		SuperPanelAlerts,
		AddPlan,
		Plan
	];

	const isAuthenticated = () => {
		const authcheck =
			token && token !== undefined;
		return authcheck;
	};

	return (
		<Route
		  {...rest}
		  render={(props) =>
			isAuthenticated() ? (
			  role === "SuperAdmin" ? (
				allowedComponentsForSuperAdmin.includes(Component) ? (
				  <Component {...props} {...passProps}/>
				) : (
				  <Redirect to="/organizations" />
				)
			  ) : (

				blockedComponentsForSchoolAdmin.includes(Component) ? (
					<Redirect to="/dashboard" />
					) : (
					<Component {...props} {...passProps}/>
				)
			  )
			) : (
			  <Redirect
				to={{
				  pathname: "/login",
				  state: { from: props.location },
				}}
			  />
			)
		  }
		/>
	  );
};

function App() {
	const token = getAccessTokenInStore();
	const role = getRoleInStore();
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const sideNavRef = useRef(null);
	const isAuthenticated = () => {
		const authcheck = token && token !== undefined;
		return authcheck;
	};
	const [notificationStatus, setNotificationStatus] = useState(false);


	useEffect(() => {
		let  status = true;
		if (getPriorityUserIdInStore()) {
			status = getPriorityNotificationStatusInStore();
		}
		else {
			status = getNotificationStatusInStore();

		}
		setNotificationStatus(status);
		removeApiLogInStore();
		removeRenewAccessTokenInProgress();
		const spinner = document.querySelector('.spinnerStyle');
		if (spinner) {
		  spinner.remove();
		}
	}, []);

	useEffect(() => {
		initGA();
		logPageView();
		// Add event listener to the document object
		document.addEventListener("mousedown", handleClickOutside);

		// Remove event listener when the component unmounts
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [sideNavRef]);

	function handleClickOutside(event) {
		if (
			!(
				event.target.id === "sidebarCollapse" || event.target.id === "hamburger"
			) &&
			sideNavRef.current &&
			!sideNavRef.current.contains(event.target)
		) {
			setSidebarOpen(false);
		}
	}

	const handleViewSidebar = () => {
		let  status = true;
		if (getPriorityUserIdInStore()) {
			status = getPriorityNotificationStatusInStore();
		}
		else {
			status = getNotificationStatusInStore();

		}
		setNotificationStatus(status);
		setSidebarOpen((prevState) => !prevState);
	};

	const updateNotificationStatus = (status) => {
		setNotificationStatus(status.toString());
	}

	return (
		<div style={{ backgroundColor: "#f2f2f2" }}>
				{/* <BrowserRouter history={history}> */}
					<div className={isAuthenticated() ? "" : "hideElements"}>
						<Header onClick={handleViewSidebar} notificationStatus={notificationStatus} userRole={role} className='topbar' />
						<div ref={sideNavRef}>
							<SideBar isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} notificationStatus={notificationStatus}/>
						</div>
					</div>
					<Switch>
						<Route exact path="/" render={() => <Redirect to="/login" />} />
						<Route exact path='/login' render={(props) => <Login {...props} updateNotificationStatus={updateNotificationStatus}/>} />
						<Route exact path='/signup' component={SignUp} />
						<Route exact path='/contactus' render={(props) => <Contactus />} />
						<Route exact path='/notFoundPage' render={(props) => <NotFoundPage />} />
						<Route exact path='/resetpassword/:token' render={(props) => <ResetPassword />} />
						<Route exact path='/verify-email/:userId/:token' render={(props) => <VerifyEmailPage />} />
						<Route
							exact
							path='/forgotpassword'
							render={(props) => <ForgotPassword />}
						/>
						<Route>
							<div className="ui container">
							<Switch>
								<PrivateRoute exact path="/dashboard" component={Home} />
								<PrivateRoute 
									exact path="/settings" 
									component={AccountSettings} 
									passProps={{ updateNotificationStatus: updateNotificationStatus }}
								/>
								<PrivateRoute exact path="/organizationSettings" component={SchoolSettings} />
								<PrivateRoute exact path="/users" component={Students} />
								<PrivateRoute exact path="/addUser" component={AddStudent} />
								<PrivateRoute exact path="/editUser/:id" component={AddStudent} />
								<PrivateRoute exact path="/organizations" component={Schools} />
								<PrivateRoute exact path="/addorganization" component={AddSchool} />
								<PrivateRoute exact path="/organizationdetails/:id" component={SchoolDetails} />
								<PrivateRoute exact path="/contactus" component={Contactus} />
								<PrivateRoute exact path="/responder" component={Staff} />
								<PrivateRoute exact path="/badge" component={Badge} />
								<PrivateRoute exact path="/addBadge" component={AddBadge} />
								<PrivateRoute exact path="/device" component={Devices} />
								<PrivateRoute exact path="/panelnotifications" component={PanelAlerts} />
								<PrivateRoute exact path="/notifications" component={SuperPanelAlerts} />
								<PrivateRoute exact path="/adddevice" component={AddDevice} />
								<PrivateRoute exact path="/privacy" component={Privacy} />
								<PrivateRoute exact path="/responderdetails/:staffId" component={StaffDetails} />
								<PrivateRoute exact path="/addResponder" component={AddStaff} />
								<PrivateRoute exact path="/verifyEmailBlock" component={VerifyEmailBlock} />
								<PrivateRoute exact path="/blocked" component={BlockPage} />
								<PrivateRoute exact path="/addplan" component={AddPlan} />
								<PrivateRoute exact path="/editplan/:id" component={AddPlan} />
								<PrivateRoute exact path="/plan" component={Plan} />
								<PrivateRoute exact path="/feedback" component={Feedbacklist} />
								<PrivateRoute exact path="/assignusers/:id" component={AssignStudents} />
								<Redirect to="/notFoundPage" />
							</Switch>
							</div>
						</Route>
					</Switch>
		</div>
	);
}

export default withRouter(App);
