const STORAGE_KEY = "etagMap"; 
const ACCESS_TOKEN_KEY = "accessToken";
const SCHOOL_NAME_KEY = "schoolName";
const REFRESH_TOKEN_KEY = "refreshToken";
const ROLE_KEY = "role";
const USER_ID_KEY = "userId";
const PRIORITY_USER_ID_KEY = "priorityUserId";
const API_CALL_KEY = "apiCallsLog";
const RENEW_ACCESS_TOKEN_KEY = "renewAccessTokenKey";
const SCHOOL_ID_KEY = "schoolId";
const USER_BLOCKED_KEY = "userBlocked";
const BLOCKED_BY_ADMIN_KEY = "blockedByAdmin";
const LOGOUT_MESSAGE_KEY = "logoutMessage";
const NOTIFICATION_STATUS_KEY = 'notificationStatus';
const PRIORITY_NOTIFICATION_STATUS_KEY = 'priorityNotificationStatus';
export const STUDENTS_ETAG_KEY = "students";
export const STUDENTS_LIST_KEY = "studentList";
export const STUDENTS_PAGECOUNT_KEY = "studentsPageCount";
export const STAFF_ETAG_KEY = "staff";
export const STAFF_LIST_KEY = "staffList";
export const STAFF_PAGECOUNT_KEY = "staffPageCount";
export const DEVICES_ETAG_KEY = "devices";
export const DEVICES_LIST_KEY = "deviceList";
export const DEVICES_PAGECOUNT_KEY = "devicesPageCount";
export const BADGES_ETAG_KEY = "badges";
export const PLANS_ETAG_KEY = "plans";
export const SCHOOLS_ETAG_KEY = "schools";
export const BADGES_LIST_KEY = "badgeList";
export const BADGES_PAGECOUNT_KEY = "badgesPageCount";
export const PANEL_ETAG_KEY = "panel";
export const PANEL_LIST_KEY = "PanelList";
export const PANEL_PAGECOUNT_KEY = "PanelPageCount";
export const HOME_ETAG_KEY = "dashboard"
export const HOME_LIST_KEY = "dashboardList"
export const PH_NO = "phoneNumber"
export const POLL_INTERVAL = 10000;
export const BLOCK_POLL_INTERVAL = 5000;
export const FEEDBACK_ETAG_KEY = "feedback";
export const FEEDBACK_LIST_KEY = "feedbackList";

let etagMap = new Map(JSON.parse(localStorage.getItem(STORAGE_KEY)));

export const getEtag = (resourceName) => {
  return etagMap.get(resourceName);
};

export const setEtag = (resourceName, etag) => {
  etagMap.set(resourceName, etag);
  localStorage.setItem(STORAGE_KEY, JSON.stringify([...etagMap]));
};

export const deleteEtag = (resourceName) => {
    etagMap.delete(resourceName);
    localStorage.setItem(STORAGE_KEY, JSON.stringify([...etagMap]));
  };

export const setAccessTokenInStore = (token) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
};

export const setSchoolNameInStore = (token) => {
    localStorage.setItem(SCHOOL_NAME_KEY, token);
};

export const setPhoneNumberInStore = (token) => {
  localStorage.setItem(PH_NO, token);
};

export const setRefreshTokenInStore = (token) => {
    localStorage.setItem(REFRESH_TOKEN_KEY, token);
};

export const setRoleInStore = (role) => {
    localStorage.setItem(ROLE_KEY, role);
};

export const setUserIdInStore = (uid) => {
    localStorage.setItem(USER_ID_KEY, uid);
};

export const setPriorityUserIdInStore = (uid) => {
    localStorage.setItem(PRIORITY_USER_ID_KEY, uid);
};

export const getAccessTokenInStore = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const getSchoolNameInStore = () => {
  return localStorage.getItem(SCHOOL_NAME_KEY);
};

export const getRefreshTokenInStore = () => {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const getRoleInStore = () => {
  return localStorage.getItem(ROLE_KEY);
};

export const getUserIdInStore = () => {
  return localStorage.getItem(USER_ID_KEY);
};

export const getPriorityUserIdInStore = () => {
  return localStorage.getItem(PRIORITY_USER_ID_KEY);
}; 

export const getPhoneNumberInStore = () => {
  return localStorage.getItem(PH_NO);
};

export const setApiLogInStore = (apiLog) => {
  localStorage.setItem(API_CALL_KEY, apiLog);
};

export const setRenewAccessTokenInProgress = (value) => {
  localStorage.setItem(RENEW_ACCESS_TOKEN_KEY, value);
};

export const getApiLogInStore = () => {
  return localStorage.getItem(API_CALL_KEY);
}; 

export const getRenewAccessTokenInProgress = () => {
  return localStorage.getItem(RENEW_ACCESS_TOKEN_KEY);
}; 

export const removeRenewAccessTokenInProgress = () => {
  localStorage.removeItem(RENEW_ACCESS_TOKEN_KEY);
};

export const removeAccessTokenInStore = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export const removeSchoolNameInStore = () => {
  localStorage.removeItem(SCHOOL_NAME_KEY);
};

export const removePhoneNumberInStore = () => {
  localStorage.removeItem(PH_NO);
};

export const removeRefreshTokenInStore = () => {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const removeRoleInStore = () => {
  localStorage.removeItem(ROLE_KEY);
};

export const removeUserIdInStore = () => {
  localStorage.removeItem(USER_ID_KEY);
}; 

export const removePriorityUserIdInStore = () => {
  localStorage.removeItem(PRIORITY_USER_ID_KEY);
}; 

export const removeApiLogInStore = () => {
  localStorage.removeItem(API_CALL_KEY);
}; 

export const clearStorage = () => {
  localStorage.clear();
}; 

export const clearLocalStorageLists = (selectedComponent) => {
  const componentsToPreserveData = [selectedComponent];
  for (let key in localStorage) {
    if (key.endsWith("List") && !componentsToPreserveData.includes(key)) {
    localStorage.removeItem(key);
    }
  }
};

export const setListInStore = (key, deviceList) => {
  localStorage.setItem(key, deviceList);
};

export const getListInStore = (key) => {
  return localStorage.getItem(key);
};

export const maxLength = (value, maximumLength) => {
  if ( value && value.length <= maximumLength) {
    return value;
  }
  return value ? value.slice(0, maximumLength): "";
};

export const getSchoolIdInStore = () => {
  return localStorage.getItem(SCHOOL_ID_KEY);
}; 

export const setSchoolIdInStore = (schoolId) => {
  localStorage.setItem(SCHOOL_ID_KEY, schoolId);
};

export const removeSchoolIdInStore = () => {
  localStorage.removeItem(SCHOOL_ID_KEY);
};

export const getUserBlockedStore = () => {
  return localStorage.getItem(USER_BLOCKED_KEY);
}; 

export const setUserBlockedInStore = (status) => {
  localStorage.setItem(USER_BLOCKED_KEY, status);
};

export const removeUserBlockedInStore = () => {
  localStorage.removeItem(USER_BLOCKED_KEY);
};

export const getBlockedByAdminStore = () => {
  return localStorage.getItem(BLOCKED_BY_ADMIN_KEY);
}; 

export const setBlockedByAdminStore = (status) => {
  localStorage.setItem(BLOCKED_BY_ADMIN_KEY, status);
};

export const removeBlockedByAdminInStore = () => {
  localStorage.removeItem(BLOCKED_BY_ADMIN_KEY);
};

export const getPageAndCountInStore = (key) => {
  return localStorage.getItem(key);
}; 

export const setPageAndCountInStore = (key, data) => {
  localStorage.setItem(key, data);
};

export const removePageAndCountInStore = (key) => {
  localStorage.removeItem(key);
};

export const getLogoutMessageInStore = () => {
  return localStorage.getItem(LOGOUT_MESSAGE_KEY);
}; 

export const setLogoutMessageInStore = (data) => {
  localStorage.setItem(LOGOUT_MESSAGE_KEY, data);
};

export const getNotificationStatusInStore = () => {
  return localStorage.getItem(NOTIFICATION_STATUS_KEY);
}; 

export const setNotificationStatusInStore = (data) => {
  localStorage.setItem(NOTIFICATION_STATUS_KEY, data);
};

export const getPriorityNotificationStatusInStore = () => {
  return localStorage.getItem(PRIORITY_NOTIFICATION_STATUS_KEY);
}; 

export const setPriorityNotificationStatusInStore = (data) => {
  localStorage.setItem(PRIORITY_NOTIFICATION_STATUS_KEY, data);
};
