import React, { useState }from "react";

import { Container } from "semantic-ui-react";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import { withRouter } from "react-router-dom";
import intl from "../../locale/en-US.json";
import { getAccessTokenInStore, getSchoolIdInStore, getUserBlockedStore, removeUserBlockedInStore, removeBlockedByAdminInStore, getRoleInStore } from "../../utils/apiUtils";
import apiInstance from "../../api";
import { maxLength } from '../../utils/apiUtils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { errorFunction } from '../../utils/errors';
import LogoutModal from '../Modal/LogoutModal';
import { Spinner } from 'react-bootstrap';
import Strings from '../../locale/en-US.json';
import { useEffect, useRef } from 'react';
import useInputFocus from '../../utils/customHooks/useInputFocus';
import AlertModal from '../Modal/AlertModal';
import { logEvent, Categories, Actions } from '../../analytics';
import {
	blockStartPolling,
	stopPolling,
	blockHandleVisibilityChange,
} from '../../utils/polling';

const Contactus = ({ history }) => {
	const id = getSchoolIdInStore();
	const [showUnsavedModal, setShowUnsavedModal] = useState(false);
	const formChanged = useRef(false);
	let nextInHistoryRef = useRef('');
	let allowNavigationRef = useRef(false);
	const handleFormChange = async (event) => {
		event.preventDefault();
		console.log(event.nativeEvent.inputType);
		if (event.nativeEvent && event.nativeEvent.inputType) {
			formChanged.current = true;
		}
	};

	useEffect(() => {
		const handleBeforeUnload = (e) => {
			if (!checkUnsavedChanges()) {
				e.preventDefault();
				e.returnValue =
					'You have unsaved changes. Are you sure you want to leave this page?';
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	const closeUnsavedModal = () => setShowUnsavedModal(false);

	const checkUnsavedChanges = () => {
		if (!formChanged.current) {
			return true;
		}
		return false;
	};

	const navigationBlockCheck = (nextLocation) => {
		nextInHistoryRef.current = nextLocation && nextLocation.pathname;
		if (allowNavigationRef.current) {
			return true;
		}
		if (checkUnsavedChanges()) {
			return true;
		} else {
			setShowUnsavedModal(true);
			return false;
		}
	};

	const handleNavigationConfirmation = () => {
		setShowUnsavedModal(false);
		allowNavigationRef.current = true;
		history.push(nextInHistoryRef.current);
	};

	useEffect(() => {
		const unblock = history.block(navigationBlockCheck);

		return () => {
			unblock();
		};
	}, [history]);

	const pollingIntervalRef = useRef(null);

	const visibilityChangeHandler = () =>
		blockHandleVisibilityChange(pollAPI, pollingIntervalRef);

	useEffect(() => {
		const role = getRoleInStore();

		if (role !== 'SuperAdmin' && getUserBlockedStore())
			blockStartPolling(pollAPI, pollingIntervalRef);

		document.addEventListener('visibilitychange', visibilityChangeHandler);
		return () => {
			stopPolling(pollingIntervalRef);

			if (pollingIntervalRef.current) {
				clearTimeout(pollingIntervalRef.current);
			}
			document.removeEventListener('visibilitychange', visibilityChangeHandler);
		};
	}, []);

	const pollAPI = async () => {
		try {
			stopPolling(pollingIntervalRef);
			const response = await apiInstance
				.get(`/api/v1/schools/${id}/status`)
				.catch((error) => {
					if (error && error.response && error.response.status === 304) {
						setErrorMessage('');
						return;
					}
					if (
						error &&
						error.response &&
						error.response.data &&
						(error.response.data.statusCode === -38 ||
							error.response.data.statusCode === -39)
					) {
						console.log('blocked');
					} else {
						const result = errorFunction(error);
						console.error('result-------- ', result);
						if (typeof result === 'boolean') {
							setShowModal2(true);
						}
					}
				});

			if (response.status === 200) {
				if (blocked) {
					setErrorMessage('');
					removeUserBlockedInStore();
					removeBlockedByAdminInStore();
					stopPolling(pollingIntervalRef);
					blocked = false;
				}
			} else blockStartPolling(pollAPI, pollingIntervalRef);
		} catch (error) {
			blockStartPolling(pollAPI, pollingIntervalRef);
		}
	};

	const token = getAccessTokenInStore();

	const [fullName, setFullName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showModal2, setShowModal2] = useState(false);
	let blocked = getUserBlockedStore();
	const isAnyInputFocused = useInputFocus();
	const searchParams = new URLSearchParams(window.location.search);
	const emailFromUrl = searchParams.get('email') || null;

	React.useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
				event.preventDefault(); // Prevent default undo behavior when no input is focused
			}
		};

		document.addEventListener('keydown', handleKeyDown);
		if (emailFromUrl) {
			setEmail(emailFromUrl);
		}
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isAnyInputFocused]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setErrorMessage('');

		if (message.trim().length < 1) {
			setErrorMessage(Strings.emptyMsg);
			return;
		}

		const data = {
			message: message,
		};
		if (!token) {
			data.name = fullName;
			data.email = email;
		}

		try {
			if (!submitted) {
				setLoading(true);
				setSubmitted(true); // contact_us_submitted_btn:"contact_us_submitted_btn_clicked",
				logEvent({
					category: Categories.feedback,
					action: Actions.contact_us_submitted_btn,
					label: getRoleInStore() == null ? '' : getRoleInStore(),
				});
				const result = await apiInstance.post('/api/v1/feedbacks', data);
				if (result.status === 200) {
					logEvent({
						category: Categories.feedback,
						action: Actions.contact_us_submitted_success,
						label: getRoleInStore() == null ? '' : getRoleInStore(),
					});
					allowNavigationRef.current = true;
					setSubmitted(true);
					setLoading(false);
					toast.success('Successfully Feedback Saved!', {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 2000,
					});

					setTimeout(() => {
						if (blocked) {
							history.goBack();
						} else {
							history.push('/dashboard');
						}
					}, 2000);
				}
			}
		} catch (error) {
			setLoading(false);
			setSubmitted(false);
			//console.log("erro-------- ", error);
			if (error) {
				console.log('erro-------- ', error);
			}
			const result = errorFunction(error);
			//console.log("result-------- ", result)
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				setErrorMessage(result);
			}
		}
	};

	return (
		<>
			<Container>
				<div>
					<div className='col-lg-6 col-md-6 col-sm-12 col-12 contactFormBread'>
						<div className='contactFormBreadLeft'>
							<Breadcrumb
								history={history}
								links={[{ link: '/contactus', title: intl.contactUs }]}
							/>
						</div>
					</div>
					<div className='card-header card_headertitle contactFormHeader col-lg-6 col-md-6 col-sm-12 col-12'>
						<div className='card-title card_head'>
							<h4 className='card_head CardTitle text-uppercase component-heading  '>
								{intl.contactUs}
							</h4>
						</div>
						{errorMessage && <div className='btn_error'>{errorMessage}</div>}
					</div>
					<div
						className='card-body col-lg-6 col-md-6 col-sm-12 col-12 contactFormHeader'
						style={{ background: '#fff' }}
					>
						<form
							id='PersonSaveForm'
							method='post'
							onChange={handleFormChange}
							onSubmit={handleSubmit}
						>
							<div className='row'>
								<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
									{token ? (
										<></>
									) : (
										<div>
											<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
												<div className='form-group'>
													<label className='form-label'>
														{intl.fullName}
														<span className='font_red'>*</span>
													</label>
													<input
														type='text'
														className='form-control'
														name={intl.fullName}
														id={intl.fullName}
														onChange={(e) => {
															formChanged.current = true;
															setFullName(maxLength(e.target.value, 50));
														}}
														value={fullName}
														required
														autoFocus
													/>
												</div>
											</div>
											<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
												<div className='form-group'>
													<label className='form-label'>
														{intl.email}
														<span className='font_red'>*</span>
													</label>
													<input
														type='email'
														className='form-control'
														accept='image/png, image/gif, image/jpeg'
														name={intl.fullName}
														id={intl.fullName}
														onChange={(e) => {
															formChanged.current = true;
															setEmail(e.target.value);
														}}
														value={email}
														required
													/>
												</div>
											</div>
										</div>
									)}
									<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
										<div className='form-group'>
											<label className='form-label'>
												{intl.message}
												<span className='font_red'>*</span>
											</label>
											<textarea
												className='form-control'
												name='LastName'
												id='LastName'
												rows='10'
												cols='50'
												onFocus={() => setErrorMessage('')}
												onChange={(e) =>
													setMessage(maxLength(e.target.value, 500))
												}
												value={message}
												required
											/>
										</div>
									</div>
								</div>
							</div>

							<div className='row'>
								<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
									<div className='float_right mr-3'>
									<div align='center'>
										<button
											className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
											type='submit'
										>
											{loading ? <Spinner size='sm' animation="border" variant="secondary" /> : Strings.submitButton}
										</button>
									</div>
									</div>

									<div className='float_right mr-3'>
										<div
											onClick={(event) => {
												event.preventDefault();
												history.goBack();
												logEvent({
													category: Categories.feedback,
													action: Actions.contact_us_cancel_btn,
													label:
														getRoleInStore() == null ? '' : getRoleInStore(),
												});
											}}
										>
											<button className='btn btn-secondary cancel-btn button-fixed'>
												{intl.cancelButton}
											</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				{showModal2 ? <LogoutModal /> : null}
				{showUnsavedModal ? (
					<AlertModal
						closeModal={closeUnsavedModal}
						header={Strings.unsavedChanges}
						body={Strings.unsavedChangesText}
						handlerFunction={handleNavigationConfirmation}
					/>
				) : null}

				<ToastContainer />
				<ToastContainer />
			</Container>
		</>
	);
};
export default withRouter(Contactus);
