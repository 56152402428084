

import { withRouter } from 'react-router-dom';
import React, { useEffect, useState, useRef } from "react";
import apiInstance from "../../api";
import Strings from "../../locale/en-US.json";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import {  ERROR_OCCURED } from "../../strings";
import { getAccessTokenInStore } from "../../utils/apiUtils";
import Spinner from "react-bootstrap/Spinner";
import { errorFunction } from "../../utils/errors";
import LogoutModal from "../Modal/LogoutModal";

const VerifyEmailPage = ({ history, match }) => {
const { userId, token } = match.params;
  const [errorMsg, setErrorMsg] = useState("");
  const [verificationMessage, setVerificationMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showLogInButton, setShowLogInButton] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [userView, setUserView] = useState(false);


  const inputRefs = [
    useRef(), useRef(), useRef(), useRef(), useRef(), useRef()
];

const emailInputRefs = [
    useRef(), useRef(), useRef(), useRef(), useRef(), useRef()
];

const [emailInputValues, setEmailInputValues] = useState(Array(6).fill(''));
const [inputValues, setInputValues] = useState(Array(6).fill(''));
const [submitted, setSubmitted] = useState(false)

  const handleInputChange = (index, event, isEmail) => {
      const input = event.target;
      const value = input.value;

      // Only allow one number in the input field
      if (value.length > 1) {
        input.value = value.slice(0, 1);
      }

      if (isEmail) {
        // Move to the next input if there's a value
        if (value && index < emailInputRefs.length - 1) {
          emailInputRefs[index + 1].current.focus();
        }
      }
      else {
        // Move to the next input if there's a value
        if (value && index < inputRefs.length - 1) {
          inputRefs[index + 1].current.focus();
        }
      }

      if (isEmail) {
        const newEmailInputValues = [...emailInputValues];
        newEmailInputValues[index] = value;
        setEmailInputValues(newEmailInputValues);
      }
      else {
        const newInputValues = [...inputValues];
        newInputValues[index] = value;
        setInputValues(newInputValues);
      }
  };

  const handleBackspace = (index, event, isEmail) => {
      
      if (event.keyCode === 13) {
          submit();
          return;
      }
      if (event.key === "e" || event.key === "E") {
        // Prevent the default action for the key press
        event.preventDefault();
        return;
    }

      
        if (event.keyCode === 9) {
            event.preventDefault();
            isEmail ? (emailInputRefs.length > (index + 1) && emailInputRefs[index + 1].current.focus()):( emailInputRefs.length > (index + 1) && inputRefs[index + 1].current.focus()); 
            return;
        }
      
      else if (isEmail) {
        if (index > 0 && event.keyCode === 8 && !emailInputRefs[index].current.value) {
          // If backspace is pressed and the current input is empty, go to the previous input
          emailInputRefs[index - 1].current.focus();
          return;
        }

      }
      else if (index > 0 && event.keyCode === 8 && !inputRefs[index].current.value) {
        // If backspace is pressed and the current input is empty, go to the previous input
        inputRefs[index - 1].current.focus();
        return;
      }

      if (isEmail) {
        const newEmailInputValues = [...emailInputValues];
        newEmailInputValues[index] = '';
        setEmailInputValues(newEmailInputValues);
      } else {
        const newInputValues = [...inputValues];
        newInputValues[index] = '';
        setInputValues(newInputValues);
      }
  };


  const submit = async () => {

      setErrorMsg('');

      if (emailInputValues.join("").length < 6 ) {
          setErrorMsg("Please enter full Email code");
          return;
      }
      
      if (inputValues.join("").length < 6 ) {
          setErrorMsg("Please enter full SMS code");
          return;
      }

      try {
          setLoading(true);
          let result;
    const verifyData = new FormData();
    verifyData.append('email_code', emailInputValues.join(""));
    verifyData.append('phone_code', inputValues.join(""));
    if (!submitted) {
      setSubmitted(true);
      result = await apiInstance.post(`api/v1/users/verify-contact/${userId}`, verifyData)
    }
          if (result && result.status === 200) {
              setLoading(false);
              toast.success('Successfully Verified!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      setTimeout(() => {
        history.push('/');
      }, 2000);
      }
        
      } catch (error) {
          let errorMsg = 'Error Occured';
    setLoading(false);
    setSubmitted(false);

          if (error) {
      console.error('------ ', error);
      if (typeof error === 'string') {
        errorMsg = error;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.statusMessage
      ) {
        errorMsg = error.response.data.statusMessage;
      }
    }
    setErrorMsg(errorMsg);
      }

  }




  const authToken = getAccessTokenInStore();
	const isAuthenticated = () => {
		const authcheck =
		authToken && authToken !== undefined;
		return authcheck;
	};
  
	function handleClick() {
    history.push("/login");
		}

  useEffect(() => {
    setLoading(true);

    const verifyEmail = async () => {
      setErrorMsg("")
      try {
        const response = await apiInstance.post(`/api/v1/auth/verify-email/${userId}/${token}`);
        if (response.status === 200) {
          setVerificationMessage("Email is verified");
          toast.success("Successfully Email Verified!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
          setTimeout(() => {
            history.push('/dashboard');
            }, 2000);
        } else {
          setVerificationMessage("Failed to verify Email");
          !isAuthenticated() && setShowLogInButton(true);
          toast.error("Failed to Verify Email!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
        }
        setLoading(false);
      } catch (error) {
        const result = errorFunction(error);
        if (typeof result === 'boolean') {
          setShowModal2(true);
        }
        setLoading(false);
		    setVerificationMessage("Failed to Verify Email");
        !isAuthenticated() && setShowLogInButton(true);
        let errorMsg = ERROR_OCCURED;
        if (error) {
          console.error('------ ', error);
          if (typeof error === 'string') {
            errorMsg = error;
          } else if (error.response && error.response.data && error.response.data.statusMessage) {
            errorMsg = error.response.data.statusMessage;
          }
        }
        setVerificationMessage(errorMsg);
        setErrorMsg(errorMsg);
      }
    };
    const searchParams = new URLSearchParams(window.location.search);
    const user = searchParams.get("type") || null;
    if (user) {
      setUserView(true);
      setLoading(false);
    }
    else{
    verifyEmail();
    }
  }, []);

  return (
    <div className='page-wrapper d-block p-4'>
      <div id='root'>
        {
          userView ? 
          <div className='wrapper'>
              <h3 className='text-center' style={{marginTop:'5em', marginBottom:'1em'}}>{Strings.verifyUserContact}</h3>
              <p>{Strings.verifyUserEmail}</p>
              <div className='input_wrapper' style={{width:"25em"}}>
                  {emailInputRefs.map((ref, index) => (
                      <input
                          key={index}
                          ref={ref}
                          type='number'
                          className='number_box'
                          placeholder='-'
                          maxLength={1}
                          onChange={(e) => handleInputChange(index, e, true)}
                          onKeyDown={(e) => handleBackspace(index, e, true)}
                          value={emailInputValues[index]} // Bind the value from state to the input
                      />
                  ))}
              </div>
              <p>{Strings.verifyUserSms}</p>
              <div className='input_wrapper' style={{width:"25em"}}>
                  {inputRefs.map((ref, index) => (
                      <input
                          key={index}
                          ref={ref}
                          type='number'
                          className='number_box'
                          placeholder='-'
                          maxLength={1}
                          onChange={(e) => handleInputChange(index, e, false)}
                          onKeyDown={(e) => handleBackspace(index, e, false)}
                          value={inputValues[index]} // Bind the value from state to the input
                      />
                  ))}
              </div>
              <div className='button_wrapper'>
                <p className='code_send'> {Strings.resendCodeFromAdmin}</p>
                  <button
                      className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
                      type='button'
                      onClick={() => submit()}
                  >
                      {loading ? <Spinner size='sm' animation="border" variant="secondary" /> :  Strings.submitButton}
                  </button>
                  {errorMsg ? <p style={{color:"red", fontSize:'small'}}>{errorMsg}</p>  : null}
              </div>
          </div>
          :
          <div className='authentications'>
          <div
            className='auth-wrapper align-items-center d-flex'
            style={{
              backgroundImage: "url()",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
            }}
          >
            <div className='container ui'>
              <div className='row'>
                  <div className='col-lg-2 col-md-2 col-2'></div>
                    <div className='col-lg-8 col-md-8 col-8'>
                  <div className='border card'>
                    <div className='card-body align-items-center'>
                      <div className='p-4'>
                        <div>
                          {errorMsg && (
                            <div className='col-lg-12 col-md-12 col-sm-12 col-12 btn_error'>
                              {errorMsg}
                            </div>
                          )}
                        </div>
                        <h1 className='font-medium mb-3 mt-8 mb-4' align='center'>
                          {verificationMessage ? verificationMessage : Strings.verifyingEmail}
                        </h1>
                        {loading && (
                          <div
                            className='mt-8 mb-8'
                            style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                          >
                            <Spinner animation="border" variant="secondary" />
                          </div>
                        )}
                      </div>
                      {showLogInButton ? (
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4 align-items-center" id="loginpage-links">
                          <span onClick={handleClick} className="login_contact blackText" style={{ cursor: 'pointer' }}>
                            {Strings.gotoLogin}
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className='col-lg-2 col-md-2 col-2'></div>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
      {showModal2 ? <LogoutModal /> : null}
      <ToastContainer />
    </div>
  );
};

export default withRouter(VerifyEmailPage);