import { Form, Image } from 'semantic-ui-react';
import '../../assets/css/style.css';

import AllImages from '../../assets/images/images.js';
import React, { useState , useRef, useEffect } from 'react';
import apiInstance from '../../api';
import Strings from '../../locale/en-US.json';
import { version } from '../../../package.json';
import { ERROR_OCCURED, PASSWORD_ERROR_LOGIN } from '../../strings';
import {
	setAccessTokenInStore,
	setRefreshTokenInStore,
	setRoleInStore,
	setUserIdInStore,
	setSchoolIdInStore,
	setPhoneNumberInStore,
	setSchoolNameInStore,
	setNotificationStatusInStore,
	setPriorityUserIdInStore,
	setPriorityNotificationStatusInStore
} from '../../utils/apiUtils';
import { maxLength } from '../../utils/apiUtils';
import { Spinner } from 'react-bootstrap';
import useInputFocus from '../../utils/customHooks/useInputFocus';
import {withRouter} from "react-router-dom";
import { Actions, Categories, logEvent } from '../../analytics';
import AlertModal from '../Modal/AlertModal';
import { errorFunction } from '../../utils/errors';
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

const LoginPage = ({ history, updateNotificationStatus }) => {
	// const [schoolDistrict, setSchoolDistrict] = useState("");
	const [showUnsavedModal, setShowUnsavedModal] = useState(false);
	const formChanged = useRef(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const isAnyInputFocused = useInputFocus();
	let nextInHistoryRef = useRef('');
	let allowNavigationRef = useRef(false);

	const blockCheck = async (id, role) =>{
		let response;
		if (role === 'SchoolAdmin') {
			response = await apiInstance.get(`/api/v1/schools/${id}/status`)
			.catch( (error)=> {
				console.log(error);
			})
		} else {
			history.push('/organizations');
		}
		
		if(response) {
			if (role === 'SchoolAdmin')
				history.push('/dashboard');
		}
	}

	const handleFormChange = async (event) => {
		event.preventDefault();
		// console.log(event.nativeEvent.inputType);
		if (event.nativeEvent && event.nativeEvent.inputType) {
			formChanged.current = true;
		}
	}

	useEffect(() => {
		const handleBeforeUnload = (e) => {
			if(!allowNavigationRef.current){
			if (!checkUnsavedChanges()) {
				e.preventDefault();
				e.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
		  	}
			}
		};
	
		window.addEventListener('beforeunload', handleBeforeUnload);
	
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	  }, []);
	  

	const closeUnsavedModal = () => setShowUnsavedModal(false);
	
	const checkUnsavedChanges = () => {
		if (!formChanged.current) {
				return true;
		}
		return false;
	};

	const navigationBlockCheck = (nextLocation) => {
		nextInHistoryRef.current = nextLocation && nextLocation.pathname;
		if(nextInHistoryRef.current.includes("forgotpassword") || nextInHistoryRef.current.includes("signup")){
			return true;
		}
		if (allowNavigationRef.current) {
			return true;
		}
		if (checkUnsavedChanges()) {
			return true;
		}
		else {
			setShowUnsavedModal(true);
			return false;
		}
	};

	  const handleNavigationConfirmation = () => {
		setShowUnsavedModal(false);
		allowNavigationRef.current = true;
		history.push(nextInHistoryRef.current);
	  };

	useEffect(() => {
		const unblock = history.block(navigationBlockCheck);
	
		return () => {
		  unblock();
		};
	  }, [history]);


	React.useEffect(() => {
		const handleKeyDown = (event) => {
		  if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
			event.preventDefault(); // Prevent default undo behavior when no input is focused
		  }
		};
	
		document.addEventListener('keydown', handleKeyDown);
	
		return () => {
		  document.removeEventListener('keydown', handleKeyDown);
		};
	  }, [isAnyInputFocused]);

	  const handleNavigation = (path) => {
		const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		// console.log("before send")
		// 	console.log(email)
		if (!email.match(emailRegex)) {
			history.push(path);
		}
		else {
			history.push(`${path}?email=${email}`);
		}
	  };

	const handleSubmit = async (e) => {
		setErrorMsg('');
		logEvent({category:Categories.authentication,action:Actions.signin_submitted_btn});
		e.preventDefault();
		if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,40}$/.test(password)) {
			setErrorMsg(PASSWORD_ERROR_LOGIN);
			return;
		}
		const userData = {
			email: email,
			password: password,
		};

		try {
			let response;

			if (!submitted) {
				setLoading(true);
				setSubmitted(true);
				response = await apiInstance.post('/api/v1/auth/login', userData);
				if (response.status === 200) {
					allowNavigationRef.current = true;
					if (response.data && response.data.user) {
						const loginInfo = response.data.user;
						setAccessTokenInStore(loginInfo.access_token);
						setSchoolNameInStore(loginInfo.school_name);
						setRefreshTokenInStore(loginInfo.refresh_token);
						setRoleInStore(loginInfo.role);
						setUserIdInStore(loginInfo.user_id);
						setPriorityUserIdInStore(loginInfo.priority_contact_user_id);
						setSchoolIdInStore(loginInfo.school_id && loginInfo.school_id._id);
						setNotificationStatusInStore(loginInfo.receive_notification && loginInfo.receive_notification);
						setPriorityNotificationStatusInStore(loginInfo.priority_receive_notification && loginInfo.priority_receive_notification);
						updateNotificationStatus(loginInfo.priority_receive_notification !== undefined ? loginInfo.priority_receive_notification: loginInfo.receive_notification)
						loginInfo.phone_no && setPhoneNumberInStore(loginInfo.phone_no)
						logEvent({category:Categories.authentication,action:Actions.signin_submitted_success});
						setLoading(false);
						blockCheck((loginInfo.school_id && loginInfo.school_id._id) || loginInfo.user_id, loginInfo.role)
					} else {
						alert('Error ', 'unable to login');
					}
				} else {
					console.error('Login Failed handling');
				}
			}
		} catch (error) {
			let errorMsg = ERROR_OCCURED;
			setLoading(false);
			setSubmitted(false);
			const result = errorFunction(error);

			if (typeof result === 'boolean') {
				setErrorMsg(errorMsg);
			} else {
				setErrorMsg(result);
			}
		}
	};

	return (
		<div className='page-wrapper d-block'>
			<div className='ui container'>
      		<div className='row'>
			  <div className='col-lg-4 col-md-2 col-2'></div>
				<div className='col-lg-4 col-md-8 col-8'>
					<div className='logo_top' align='center'>
						<Image src={AllImages["logo"]} alt="Logo" className='logo_class' />
						<p>v {version}</p>
					</div>
				</div>
				<div className='col-lg-4 col-md-2 col-2'></div>
				</div>
			</div>
			<div id='root'>
				<div className='authentications '>
					<div
						className='auth-wrapper align-items-center d-flex'
						style={{
							backgroundImage: 'url()',
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'center center',
						}}
					>
						<div className='ui container '>
						{/* <div className='container'> */}
      						<div className='row '>
							  	<div className='col-lg-3 col-md-1 col-sm-1 col-1'></div>
								  <div className='col-lg-6 col-md-10 col-sm-10 col-10 '>
									<div className='border card'>
										<div className='card-body'>
											<div className='p-4'>
												<h3 className='font-medium mb-3' align='center'>
													{Strings.signIn}
												</h3>
												<Form onChange={handleFormChange} onSubmit={handleSubmit}>
													<div className='error mb-2'></div>
													<div className='mb-4' style={{ display: 'flex' }}>
														<span className='themify-container'>
														<img src={AllImages["key"]} alt='Key Icon'  className='themify-icon-medium'/>
														</span>
														<input
															placeholder={Strings.email}
															type='email'
															value={email}
															onChange={(e) =>
																setEmail(maxLength(e.target.value, 50))
															}
															required
															className='themify-input border_right'
														/>
													</div>
													<div className='error mb-2'></div>
													<div className='mb-4' style={{ display: 'flex' }}>
														<span className='themify-container'>
															<img src={AllImages["user"]} alt='Key Icon'  className='themify-icon-medium'/>
															{/* <i className='ti-key 
															themify-icon-medium'></i>
														*/}
														</span>
														<input
															id='password'
															name='password'
															placeholder={Strings.password}
															type={showPassword ? 'text': 'password'}
															value={password}
															onChange={(e) =>
																setPassword(maxLength(e.target.value, 50))
															}
															required
															className='themify-input'
														/>
														<span className='show_password_container' onClick={() => setShowPassword(!showPassword)}>
															{showPassword ? <FaEye/> : <FaEyeSlash/>}
														</span>
													</div>
													<div className='error mb-2'></div>
													{errorMsg && (
														<p className='error-message'>{errorMsg}</p>
													)}
													<div align='center'>
														<button className='btn btn-login btn-lg btn-block loginloader LoginBtnLoader mb-2 '>
															{loading ? <Spinner size='sm' animation="border" variant="secondary" /> : Strings.signIn}
														</button>
													</div>
													{/* <div className='mb-3 col-lg-12 col-md-12 col-sm-12 col-12'> */}
													<div className='row'>
														<div
															className='col-lg-4 col-md-4 col-sm-4 col-12 '
															// id='loginpage-links'
														>
															<span className='login_contact float-left blackText hover-pointer'>
																<span onClick={() => {handleNavigation('/signup');logEvent({category:Categories.authentication,action:Actions.signup_btn})}}>{Strings.signUp}</span>
															</span>
														</div>
														<div
															className='col-lg-4 col-md-4 col-sm-4 col-12 justify-content-center'
															// id='loginpage-links'
														>
															<span className='login_contact blackText hover-pointer'>
																<span onClick={() => {handleNavigation('/forgotpassword');logEvent({category:Categories.authentication,action:Actions.forgot_pwd_btn})}}>{Strings.forgotPassword}</span>
															</span>
														</div>
														<div
															className='col-lg-4 col-md-4 col-sm-4 col-12'
															// id='loginpage-links'
														>
															<span className='login_contact  float	 blackText hover-pointer'>
																<span onClick={() => {handleNavigation('/contactus');logEvent({category:Categories.feedback,action:Actions.contact_us_btn}) }}>{Strings.contactUs}</span>
															</span>
														</div>
													</div>
													{/* </div> */}
												</Form>
											</div>
											{showUnsavedModal ? (
												<AlertModal
													closeModal={closeUnsavedModal}
													header={Strings.unsavedChanges}
													body={Strings.unsavedChangesText}
													handlerFunction={handleNavigationConfirmation}
												/>
											) : null}

										</div>
									</div>
								</div>
								<div className='col-lg-3 col-md-1 col-sm-1 col-1'></div>
								</div>
							</div>
						{/* </div> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(LoginPage);
