import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import apiInstance from '../../api';
import Strings from '../../locale/en-US.json';
import { DEVICENAME_LENGTH_ERROR, DEVICENO_LENGTH_ERROR ,INVALID_DEVICE_ID} from '../../strings';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { maxLength } from '../../utils/apiUtils';
import { Spinner } from 'react-bootstrap';
import { errorFunction } from '../../utils/errors';
import LogoutModal from '../Modal/LogoutModal';
import { Dropdown } from 'semantic-ui-react';
import intl from '../../locale/en-US.json';
import useInputFocus from '../../utils/customHooks/useInputFocus';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import AlertModal from '../Modal/AlertModal';
import { Actions, Categories, logEvent } from '../../analytics';
import { FaCamera } from "react-icons/fa";
import QRScanModal from '../Modal/QRScanModal';
import {tz} from 'moment-timezone';
import { getCameraForQrScan } from '../../utils/common';
const DEVICENAME_MIN_LENGTH = 3;
const DEVICENAME_MAX_LENGTH = 50;
const DEVICENO_MIN_LENGTH = 12;
const DEVICENO_MAX_LENGTH = 20;

const AddOrEditDevice = ({ history, location }) => {
	const [deviceName, setDeviceName] = useState('');
	const [deviceNumber, setDeviceNumber] = useState('');
	const [type, setType] = useState('Panel');
	const deviceNameRef = useRef(deviceName);
	const deviceNumberRef = useRef(deviceNumber);
	const typeRef = useRef(type);
	const initialEditDeviceNumber = useRef('');
	const initialEditDeviceName = useRef('');
	const initialEditType = useRef('Panel');
	const [showModal, setShowModal] = useState(false);
	const [showQRModal, setShowQRModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showModal2, setShowModal2] = useState(false);
	let nextInHistoryRef = useRef('');
	let allowNavigationRef = useRef(false);
	
	const isAnyInputFocused = useInputFocus();

	useEffect(() => {
		const handleBeforeUnload = (e) => {
			if (!checkUnsavedChanges()) {
				e.preventDefault();
				e.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
		  	}
		};
	
		window.addEventListener('beforeunload', handleBeforeUnload);
	
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	  }, []);

	  const closeModal = () => setShowModal(false);
	  const openQRModal = () => setShowQRModal(true);
	  const closeQRModal = () => setShowQRModal(false);

	useEffect(() => {
		deviceNameRef.current = deviceName;
	}, [deviceName]);
	
	useEffect(() => {
		deviceNumberRef.current = deviceNumber;
	}, [deviceNumber]);

	useEffect(() => {
		typeRef.current = type;
	}, [type]);

	const handleScan = (data) => {
		if (!!data) {
			if (
				data.trim().toLowerCase === INVALID_DEVICE_ID
			) {
				closeQRModal();
				const scannedText = data + 'invalid';
				setErrorMessage(Strings.scannedDeviceNumber  	+ " " + scannedText +' : invalid');
				return;
			}
			else if (
				data.trim().length < DEVICENO_MIN_LENGTH ||
				data.trim().length > DEVICENO_MAX_LENGTH
			) {
				closeQRModal();
				const scannedText = data.length < 50 ? data : " of length " + data.length;
				setErrorMessage(Strings.scannedDeviceNumber  	+ " " + scannedText +' : ' + DEVICENO_LENGTH_ERROR);
				return;
			}
			setDeviceNumber(data.toUpperCase());
			closeQRModal();
			setErrorMessage("")
		}
	  };
	
	  const handleStartQrScan   = async ()  => {
		try {
			const cameraRequestResult = await getCameraForQrScan();		
			if (cameraRequestResult === true) {
			  openQRModal();
			} else {
			  setErrorMessage(cameraRequestResult);
			}
		  } catch (error) {
			setErrorMessage(error);
		  }
	  };

	const navigationBlockCheck = (nextLocation) => {
		nextInHistoryRef.current = nextLocation && nextLocation.pathname;
		if (allowNavigationRef.current) {
			return true;
		}
		if (checkUnsavedChanges()) {
			return true;
		}
		else {
			setShowModal(true);
			return false;
		}
	};
	
	
	const checkUnsavedChanges = () => {
		if (deviceNameRef.current) {
			if (!(initialEditDeviceName.current && initialEditDeviceName.current === deviceNameRef.current)) {
				return false;
			}
		}
		
		if (deviceNumberRef.current) {
			if (!(initialEditDeviceNumber.current && initialEditDeviceNumber.current === deviceNumberRef.current)) {
				return false;
			}
		}
		
		if (typeRef.current) {
			if (!(initialEditType.current && initialEditType.current === typeRef.current)) {
				return false;
			}
		}
		return true;
	};

	  const handleNavigationConfirmation = () => {
		setShowModal(false);
		allowNavigationRef.current = true;
		history.push(nextInHistoryRef.current);
	  };

	useEffect(() => {
		const unblock = history.block(navigationBlockCheck);
	
		return () => {
		  unblock();
		};
	  }, [history]);

	useEffect(() => {
		const handleKeyDown = (event) => {
		  if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
			event.preventDefault(); // Prevent default undo behavior when no input is focused
		  }
		};
	
		document.addEventListener('keydown', handleKeyDown);
	
		return () => {
		  document.removeEventListener('keydown', handleKeyDown);
		};
	  }, [isAnyInputFocused]);

	const deviceOptions = [
		{ key: 1, text: 'Panel', value: 'Panel' },
		{ key: 2, text: 'Gateway', value: 'Gateway' },
	];

	useEffect(() => {
		if (location.state && location.state.data) {
			const { device_name, device_number, device_type } = location.state.data;
			setDeviceName(device_name);
			setDeviceNumber(device_number);
			setType(device_type);
			initialEditDeviceNumber.current= device_number;
			initialEditType.current= device_type;
			initialEditDeviceName.current= device_name;
		}
	}, [location.state]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (
			deviceName.trim().length < DEVICENAME_MIN_LENGTH ||
			deviceName.trim().length > DEVICENAME_MAX_LENGTH
		) {
			setErrorMessage(Strings.deviceName + ' : ' + DEVICENAME_LENGTH_ERROR);
			return;
		} 
		else if (
			deviceNumber.trim().toLowerCase() === INVALID_DEVICE_ID
		) {
			setErrorMessage(Strings.deviceNumberInvalid + ' : ' + INVALID_DEVICE_ID);
			return;
		}
		else if (
			deviceNumber.trim().length < DEVICENO_MIN_LENGTH ||
			deviceNumber.trim().length > DEVICENO_MAX_LENGTH
		) {
			setErrorMessage(Strings.deviceNumber + ' : ' + DEVICENO_LENGTH_ERROR);
			return;
		}
		if (deviceName && deviceNumber && type) {
			try {
				const timezone = tz.guess();
				const timezoneCode = tz(tz.guess()).zoneAbbr();
				const editDeviceData = new FormData();
				editDeviceData.append('device_name', deviceName.trim());
				editDeviceData.append('device_number', deviceNumber.trim());
				editDeviceData.append('device_type', type);
				editDeviceData.append('timezone', timezone);
				editDeviceData.append('timezone_code', timezoneCode);
				setDeviceName(deviceName.trim())
				setDeviceNumber(deviceNumber.trim())
				if (location.state && location.state.data) {
					logEvent({category:Categories.device,action:Actions.device_edit_submit_btn});
					const { _id } = location.state.data;
					try {
						let response;
						if (!submitted) {
							setLoading(true);
							setSubmitted(true);
							response = await apiInstance
								.put(`/api/v1/devices/${_id}`, editDeviceData)
								.catch((error) => {
																		setErrorMessage(
										error.response && error.response.data
											? error.response.data.statusMessage
											: 'Unknown error occurred'
									);
								});
							setLoading(false);
						}
						if (response && response.status === 200) {
							logEvent({category:Categories.device,action:Actions.device_edit_success});
							allowNavigationRef.current = true;
							toast.success('Successfully Saved!', {
								position: toast.POSITION.TOP_CENTER,
								autoClose: 2000, // Toast message will be shown for 2 seconds
							});

							setTimeout(() => {
								history.push('/device');
							}, 2000);
						} else {
							setSubmitted(false);
						}
					} catch (error) {
						setSubmitted(false);
						setLoading(false);
						const result = errorFunction(error);
						//console.log('result-------- ', result);
						if (typeof result === 'boolean') {
							setShowModal2(true);
						} else {
														setErrorMessage(result);
						}
					}
				} else {
					if (!submitted) {
						logEvent({category:Categories.device,action:Actions.device_add_submit_btn});
						setLoading(true);
						setSubmitted(true);

						const addDeviceData = {
							device_name: deviceName.trim(),
							device_number: deviceNumber.trim(),
							device_type: type,
							timezone: timezone,
							timezone_code: timezoneCode
						  };

						const deviceDataArray = [
							addDeviceData
						];
						const response = await apiInstance
							.post('/api/v1/devices', deviceDataArray)
							.catch((error) => {
								const result = errorFunction(error);
								if (typeof result === 'boolean') {
									setShowModal2(true);
								} else {
									const cleanedError = result.includes('"[0].device_number"') ? result.replace('"[0].device_number"', 'Device Number') : result;
									setErrorMessage(cleanedError);
								}
							});

						setLoading(false);
						if (response && response.status === 200) {
							logEvent({category:Categories.device,action:Actions.device_add_success});
							allowNavigationRef.current = true;
							toast.success('Successfully Saved!', {
								position: toast.POSITION.TOP_CENTER,
								autoClose: 2000, // Toast message will be shown for 2 seconds
							});

							setTimeout(() => {
								history.push('/device');
							}, 2000);
						} else {
							setSubmitted(false);
						}
					}
				}
			} catch (error) {
				setLoading(false);
				setSubmitted(false);
				const result = errorFunction(error);
				if (typeof result === 'boolean') {
					setShowModal2(true);
				} else {
					setErrorMessage(result);
				}
			}
		}
	};

	return (
		<>
			<div>
				<Breadcrumb
					links={[
						{ link: '/device', title: intl.deviceList},
						{
							link: '',
							title:
								location.state && location.state.data
									? intl.updateDevice
									: intl.addDevice,
						},
					]}
				/>

				<div className='card-header card_headertitle'>
					<div className='card-title card_head'>
						<h4 className='card_head CardTitle text-uppercase component-heading  '>
							{location.state && location.state.data
								? Strings.updateDeviceButton
								: Strings.addDeviceButton}
						</h4>
					</div>
					{errorMessage && (
						<div className='col-lg-12 col-md-12 col-sm-12 col-12 btn_error'>
							{errorMessage}
						</div>
					)}
				</div>
				<div className='card-body' style={{ background: '#fff' }}>
					<form id='PersonSaveForm' onSubmit={handleSubmit} action=''>
						<div className='row'>
							<div className='col-lg-6 col-md-12 col-sm-12 col-12'>
								<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
									<div className='form-group'>
										<label className='form-label'>
											{Strings.deviceName}
											<span className='font_red'>*</span>
										</label>
										<input
											type='text'
											className='form-control'
											name='BeaconMac'
											value={deviceName}
											onChange={(event) =>
												setDeviceName(maxLength(event.target.value, 50))
											}
											required
											autoFocus
										/>
									</div>
								</div>

								<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
									<div className='form-group'>
										<label className='form-label'>
											{Strings.deviceNumber}
											<span className='font_red'>*</span>
										</label>
										<div className='row'>
											<div style={{paddingRight: '0px !important'}} className='col-lg-9 col-md-9 col-sm-9 col-9'>
												<input
													type='text'
													className='form-control'
													name='BeaconMac'
													value={deviceNumber}
													onChange={(event) =>
														setDeviceNumber(
															maxLength(event.target.value.toLocaleUpperCase(), 20)
														)
													}
													required
												/>
											</div>
											<div style={{paddingLeft: '0px !important'}} className='col-lg-3 col-md-3 col-sm-9 col-9'>
												<span												
														title='Qr Scan Device Number'
														className='btn AddButton btn-sm bulk_buttons mt-2'
														onClick={(event) => 
															{event.preventDefault();
															logEvent({category:Categories.device,action:Actions.device_Qr_scan_btn});
															handleStartQrScan()}
														}
													>
														<i>
															<FaCamera />
														</i>{' '}
														QR Scan
													</span>
												</div>
											</div>

									</div>
								</div>
								<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
									<label className='form-label'>{Strings.type}</label>
									<div className='form-group'>
										<span className='GenderGroup'>
											<Dropdown
												options={deviceOptions}
												required
												selection
												className='w-100'
												placeholder='Gender'
												value={type}
												onChange={(e, { value }) => setType(value)}
											/>
											{/* <select
												className='drop-down'
												value={type}
												onChange={handleTypeChange}
											>
												<option value='Panel'>Panel</option>
												<option value='Gateway'>Gateway</option>
											</select> */}
										</span>
									</div>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
								<div className='float_right mr-3'>
									<div align='center'>
										<button
											className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
											type='submit'
										>
											{loading ? <Spinner size='sm' /> : Strings.submitButton}
										</button>
									</div>
								</div>
								<div className='float_right mr-3'>
									<span										className='btn btn-secondary cancel-btn button-fixed'
										onClick={(event) => {
											event.preventDefault();
											history.push('/device');
											if(location.state && location.state.data){
												logEvent({category:Categories.device,action:Actions.device_edit_cancel_btn});
                 			
											}else{
												logEvent({category:Categories.device,action:Actions.device_add_cancel_btn});
                 			
											}
											}
										}
									>
										{intl.cancelButton}
									</span>
								</div>
							</div>
						</div>
					</form>
					{showQRModal ?(
					<QRScanModal
						closeModal={closeQRModal}
						header={Strings.scanQrDeviceNumber}
						showQRScanner={true}
						handleScanResult={handleScan}
					/>):""}
					{showModal ? (
						<AlertModal
							closeModal={closeModal}
							header={Strings.unsavedChanges}
							body={Strings.unsavedChangesText}
							handlerFunction={handleNavigationConfirmation}
						/>
					) : null}
				</div>
				{showModal2 ? <LogoutModal /> : null}
				<ToastContainer />
			</div>
		</>
	);
};
export default withRouter(AddOrEditDevice);
