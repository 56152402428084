import React from 'react';
import { withRouter } from 'react-router-dom';

import apiInstance from '../../api';
import { useState, useRef, useEffect } from 'react';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import intl from '../../locale/en-US.json';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { phoneCodes } from '../../utils/phoneCodes';
import { maxLength } from '../../utils/apiUtils';
import Strings from '../../locale/en-US.json';
import { Dropdown } from 'semantic-ui-react';
import { Spinner } from 'react-bootstrap';
import { PHONENO_ERROR, LENGTH_MAX } from '../../strings';
import { errorFunction } from '../../utils/errors';
import LogoutModal from '../Modal/LogoutModal';
import useInputFocus from '../../utils/customHooks/useInputFocus';
import { AsyncPaginate } from 'react-select-async-paginate';
import AlertModal from '../Modal/AlertModal';
import { FaTimesCircle } from 'react-icons/fa';
import { Categories, logEvent, Actions } from '../../analytics';

const MAX_LENGTH = 255;
const MIN_LENGTH = 3;

const StaffDetails = ({ history, location }) => {
	if (location && (location.state === undefined || location.state === null)) {
		history.push('/');
		return null;
	}

	const { data } = location.state;
	const [badgeNumber, setBadgeNumber] = useState(null);
	const [firstName, setFirstName] = React.useState(data.first_name);
	const [lastName, setLastName] = React.useState(data.last_name);
	const gender='Male';
	const [emailSubmitted, setEmailSubmitted] = useState(false);
	const [email, setEmail] = React.useState(data.email);
	const [phoneAreaCode, setAreaPhoneCode] = React.useState(
		data.phone_no.split('-')[0]
	);
	const [phoneNumber, setPhoneNumber] = React.useState(
		data.phone_no.split('-')[1]
	);
	const [errorMessage, setErrorMessage] = useState('');

	const [showModal2, setShowModal2] = React.useState(false);

	const [submitted, setSubmitted] = useState(false);

	const [loading, setLoading] = useState(false);
	const unverified =
		data.email_verification_code || data.phone_verification_code ? true : false;
	const [resendLoader, setResendLoader] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const isAnyInputFocused = useInputFocus();
	const [showUnsavedModal, setShowUnsavedModal] = useState(false);
	const [receiveNotification, setReceiveNotification] = React.useState(
		data.receive_notification === false ? false : true
	);
	const [receiveNotificationOriginal, setReceiveNotificationOriginal] =
		React.useState(data.receive_notification === false ? false : true);
	const [sendNotification, setSendNotification] = React.useState(
		data.send_notification === false ? false : true
	);
	const [sendNotificationOriginal, setSendNotificationOriginal] =
		React.useState(data.send_notification === false ? false : true);
	const formChanged = useRef(false);
	let nextInHistoryRef = useRef('');
	let allowNavigationRef = useRef(false);

	React.useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
				event.preventDefault();
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isAnyInputFocused]);

	// const genderOptions = [
	// 	{ key: 1, text: 'Male', value: 'Male' },
	// 	{ key: 2, text: 'Female', value: 'Female' },
	// 	{ key: 3, text: 'Other', value: 'Other' },
	// ];

	React.useEffect(() => {
		getBadges();
	}, []);

	const showError = (result) => {
		setErrorMessage(result);
		if (result) {
			window.scrollTo(0, 0);
		}
	};

	const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];

	const customStyles = {
		// menuList: (provided, state) => ({
		// 	...provided,
		// 	'& div:first-child': {
		// 	  fontWeight: 'bold',
		// 	},
		// }),

		// container : (provided, state) => ({
		// 	width:'98%',
		// 	height: '3em'
		// }),
		menu: (provided, state) => ({
			...provided,
			top: 'auto',
			width: '100%',
			// bottom: '100%',
		}),
		control: (provided, state) => ({
			...provided,
			borderRadius: '5px',
			// border: '0.5px solid grey',
			boxShadow: state.isFocused ? '0 0 0 0.2px grey' : null,
			'&:hover': {
				border: '1px solid grey',
			},
			height: '35px',
			display: 'flex',
			alignItems: 'center',
			flexWrap: 'nowrap',
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isFocused ? 'rgba(0,0,0,.03)' : null,
			color: 'black',
			fontWeight: state.isSelected ? 'bold' : null,
		}),
		ValueContainer: (provided, state) => ({
			...provided,
			height: '35px',
			display: 'flex',
			alignItems: 'center',
		}),
	};

	const resendCodes = async () => {
		setResendLoader(true);
		try {
			let response;
			if (!emailSubmitted) {
				setEmailSubmitted(true);
				response = await apiInstance.post(
					`/api/v1/users/resend-verification-codes/${data._id}`
				);
				setResendLoader(false);
				if (response.status === 200) {
					logEvent({
						category: Categories.authentication,
						action: Actions.resend_verification_email_success,
					});
					setEmailSubmitted(false);
					toast.success('Successfully sent Email and SMS codes!', {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 5000,
					});
				}
			}
		} catch (error) {
			setEmailSubmitted(false);
			setResendLoader(false);
			if (error) {
				let errorMsg = 'Error in Verifying Email';
				console.error('------ ', error);
				if (typeof error === 'string') {
					errorMsg = error;
				} else if (
					error.response &&
					error.response.data &&
					error.response.data.statusMessage
				) {
					errorMsg = error.response.data.statusMessage;
				}
				console.log(errorMsg);
			}
		}
	};

	const loadOptions = async (searchQuery, loadedOptions, { page }) => {
		try {
			const result = await apiInstance.get(
				`/api/v1/badges?page=${page}&limit=200`
			);

			const assignedBaged = {
				name: data.mac_address,
				value: data.badge_id,
			};

			const unassignBadges = result.data.data.badges.filter(
				(badge) => badge.user.role === undefined
			);
			let options;
			// if there are no unassign badge and a badge is also not assigned
			if (page === 1 && unassignBadges.length === 0 && !data.badge_id) {
				// setBadgeNumber({ name: 'No badges available', value: null });
				options = [{ name: 'No badges available', value: null }];
			} else {
				options = unassignBadges.map((badge) => {
					// if (badge.mac_address !== assignedBaged.name) {
					return {
						name: badge.mac_address,
						value: badge._id,
					};
					// }
				});
			}

			// setAllUnassignedBadges(
			if (data.badge_id) {
				options = [assignedBaged, ...options];
			} else {
				options = [...options];
			}
			// options =  ?  : [...options]
			// );

			return {
				options,
				hasMore: unassignBadges.length >= 10,
				additional: {
					page: page + 1,
				},
			};
		} catch (error) {
			const result = errorFunction(error);

			console.log('error-------- ', result);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				showError(result);
			}
		}
	};
	async function getBadges() {
		try {
			setLoading(true);
			const result = await apiInstance.get('/api/v1/badges');
			const assignedBadge = result.data.data.badges.filter(
				(badge) => badge._id === data.badge_id
			);

			// console.log({ name: assignedBadge.mac_address, value: data.badge_id });

			assignedBadge.length >= 1 &&
				setBadgeNumber({
					name: assignedBadge[0].mac_address,
					value: data.badge_id,
				});
			setLoading(false);

			// setting phone area code
			const code = phoneCodes.find(
				(phoneCode) =>
					data.phone_no.split('-')[0] === phoneCode.value.split(' ')[0]
			);
			setAreaPhoneCode(code.text);

			showError('');
		} catch (error) {
			const result = errorFunction(error);

			console.log('error-------- ', result);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				showError(result);
			}
		}
	}

	const handleFormChange = async (event) => {
		const { type } = event.target;
		if (type === 'checkbox') {
			return;
		}
		event.preventDefault();
		if (event.nativeEvent && event.nativeEvent.inputType) {
			formChanged.current = true;
		}
	};

	useEffect(() => {
		const handleBeforeUnload = (e) => {
			if (!checkUnsavedChanges()) {
				e.preventDefault();
				e.returnValue =
					'You have unsaved changes. Are you sure you want to leave this page?';
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	const closeUnsavedModal = () => setShowUnsavedModal(false);
	const handleReceiveNotificationSwitchChange = () => {
		setReceiveNotification(!receiveNotification);
	};

	const handleSendNotificationSwitchChange = () => {
		setSendNotification(!sendNotification);
	};

	const checkUnsavedChanges = () => {
		if (!formChanged.current) {
			return true;
		}
		return false;
	};

	const navigationBlockCheck = (nextLocation) => {
		nextInHistoryRef.current = nextLocation && nextLocation.pathname;
		if (allowNavigationRef.current) {
			return true;
		}
		if (checkUnsavedChanges()) {
			return true;
		} else {
			setShowUnsavedModal(true);
			return false;
		}
	};

	const handleNavigationConfirmation = () => {
		setShowUnsavedModal(false);
		allowNavigationRef.current = true;
		history.push(nextInHistoryRef.current);
	};

	useEffect(() => {
		const unblock = history.block(navigationBlockCheck);

		return () => {
			unblock();
		};
	}, [history]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		showError('');
		logEvent({
			category: Categories.staff,
			action: Actions.staff_edit_submit_btn,
		});
		if (
			firstName.trim().length < MIN_LENGTH ||
			firstName.trim().length > MAX_LENGTH
		) {
			showError(Strings.firstName + ' : ' + LENGTH_MAX);
			return;
		}

		if (
			lastName.trim().length < MIN_LENGTH ||
			lastName.trim().length > MAX_LENGTH
		) {
			showError(Strings.lastName + ' : ' + LENGTH_MAX);
			return;
		}

		if (
			phoneAreaCode.split(' ')[0].split('').slice(1).join('').length +
				phoneNumber.length <
				8 ||
			phoneAreaCode.split(' ')[0].split('').slice(1).join('').length +
				phoneNumber.length >
				15
		) {
			showError(PHONENO_ERROR);
			return;
		}
		const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		if (!email.match(emailRegex)) {
			showError('Invalid Email format');
			return false;
		}

		const atIndex = email.indexOf('@');
		const lastCharBeforeAt = email[atIndex - 1];
		const lastCharBeforeAtRegex = /^[a-zA-Z0-9]$/;
		if (!lastCharBeforeAt.match(lastCharBeforeAtRegex)) {
			showError('Invalid Email format');
			return false;
		}

		let dataBody = {
			first_name: firstName.trim(),
			last_name: lastName.trim(),
			email: email,
			gender: gender,
			phone_no: `${phoneAreaCode.split(' ')[0]}-${phoneNumber}`,
			role: intl.roleTeacher,
			send_notification: sendNotification,
			receive_notification: receiveNotification,
		};
		setFirstName(firstName.trim());
		setLastName(lastName.trim());
		if (badgeNumber !== null && badgeNumber.value !== null) {
			dataBody.badge_id = badgeNumber.value;
		}

		// if (badgeNumber !== '-- SELECT --') {
		// 	if (badgeNumber !== '') {
		// 		dataBody.badge_id = badgeNumber;
		// 	}

		// if (badgeNumber !== '-- SELECT --') {
		// 	if (badgeNumber !== '') {
		// 		dataBody.badge_id = badgeNumber;
		// 	}
		// }

		try {
			if (!submitted) {
				setLoading2(true);
				setSubmitted(true);
				const result = await apiInstance.put(
					`/api/v1/users/${data._id}`,
					dataBody
				);
				if (receiveNotificationOriginal !== receiveNotification) {
					logEvent({
						category: Categories.staff,
						action: Actions.staff_edit_notification_receive_status,
						value: receiveNotification,
					});
				}
				if (sendNotificationOriginal !== sendNotification) {
					logEvent({
						category: Categories.staff,
						action: Actions.staff_edit_notification_send_status,
						value: sendNotification,
					});
				}
				setSendNotificationOriginal(sendNotification);
				setReceiveNotificationOriginal(receiveNotification);
				logEvent({
					category: Categories.staff,
					action: Actions.staff_edit_success,
				});
				allowNavigationRef.current = true;
				if (result && result.status === 200) {
					toast.success('Successfully Saved!', {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 2000,
					});
				}

				setLoading2(false);

				setTimeout(() => {
					history.push('/responder');
				}, 2000);
			}
		} catch (error) {
			setSubmitted(false);
			setLoading2(false);
			const result = errorFunction(error);
			//console.log('result-------- ', result);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				showError(result);
			}
		}
	};

	return (
		<div>
			<Breadcrumb
				links={[
					{ link: '/responder', title: intl.staffList },
					{ link: '', title: intl.staffDetails },
				]}
			/>

			<div className='card-header card_headertitle d-flex justify-content-between '>
				<div className='card-title card_head'>
					<h4 className='card_head CardTitle text-uppercase component-heading  '>
						{intl.staffDetails}
					</h4>
				</div>
			</div>
			{errorMessage && (
				<div className='col-lg-12 col-md-12 col-sm-12 col-12 btn_error'>
					{errorMessage}
				</div>
			)}
			{loading && (
				<div className='d-flex justify-content-center align-items-center'>
					<Spinner animation='border' variant='secondary' />
				</div>
			)}
			<div
				className='card-body  blocked-class'
				style={{ background: '#fff' }}
			>
				<form
					id='PersonSaveForm'
					onChange={handleFormChange}
					onSubmit={handleSubmit}
					action=''
				>
					<div className='row'>
						<div className='col-lg-6 col-md-6 col-sm-12 col-12'>
							<div className='form-group'>
								<label className='form-label'>
									{intl.firstName}
									<span className='font_red'>*</span>
								</label>
								<input
									type='text'
									className='form-control form-control-staff-detail'
									value={firstName}
									onChange={(event) =>
										setFirstName(maxLength(event.target.value, 50))
									}
									required
								/>
							</div>
						</div>
					</div>

					<div className='row'>
						<div className='col-lg-6 col-md-6 col-sm-12 col-12'>
							<div className='form-group'>
								<label className='form-label'>
									{intl.lastName}
									<span className='font_red'>*</span>
								</label>
								<input
									type='text'
									className='form-control form-control-staff-detail'
									value={lastName}
									onChange={(event) =>
										setLastName(maxLength(event.target.value, 50))
									}
									required
								/>
							</div>
						</div>
					</div>
					<div className={!unverified ? "" : 'col-lg-6 col-md-6 col-sm-12 col-12 pl-2 pr-2'} style={!unverified?{}:{backgroundColor:'#f2f2f2'}}>
					{ !unverified ? "":
						<div style={{}} >
							<div  className='cursor-pointer float-right mt-2 mb-2 mr-2'id='loginpage-links'
							>
								{resendLoader ?<Spinner size='sm'/>: 
								<p className=' login_contact float-right blueText'
								onClick={() => resendCodes()}
									style={{cursor:"pointer", fontWeight:300, fontSize:"0.76rem"}}
								>
								
								{Strings.resendCodes}
								</p>
							}
							</div>
							<hr style={{ width: '100%' }} />
						</div>
					}
					<div className='row'>
						<div className={!unverified? 'col-lg-6 col-md-6 col-sm-12 col-12': 'col-lg-12 col-md-12 col-sm-12 col-12'}>
							<div className='form-group'>
								<label className='form-label d-flex mb-0'>
									{intl.email}
									<span className='font_red'>*</span>
											<span style={{ fontSize: '10px', fontWeight: '150', marginLeft: '5px' }}>
												({intl.emailReasonMessage})
											</span>
									{/* {!unverified ? (
										''
									) : (
										<div>
											<p
												className=' login_contact float-left mr-2'
												style={{ fontWeight: '300' }}
											>
												(Unverified Contacts)
											</p>
											<div
												className='cursor-pointer float_right'
												id='loginpage-links'
											>
												{resendLoader ? (
													<Spinner size='sm' />
												) : (
													<p
														className=' login_contact float-right blackText'
														onClick={() => resendCodes()}
														style={{ cursor: 'pointer', fontWeight: 300 }}
													>
														{Strings.resendCodes}
													</p>
												)}
											</div>
										</div>
									)} */}
								</label>
								<input
									type='email'
									className='form-control form-control-staff-detail'
									value={email}
									onChange={(event) =>
										setEmail(maxLength(event.target.value, 50))
									}
									required
								/>
							</div>
						</div>
						</div>
						<div className='row'>
						<div className={!unverified? 'col-lg-6 col-md-6 col-sm-12 col-12': 'col-lg-12 col-md-12 col-sm-12 col-12'}>
							<div className='row'>
								<label className='form-label mb-0' style={{marginLeft:'3px'}}>{intl.phoneNo}
								<span className='font_red'>*</span>
									<span style={{ fontSize: '10px', fontWeight: '150', marginLeft: '5px' }}>
										({intl.smsReasonMessage})
								</span>
								</label>

								<div
									className='form-group col-lg-4 col-md-6 col-sm-12 col-12'
									style={{ marginTop: '10px' }}
								>
									<Dropdown
										options={phoneCodes}
										selection
										search
										style={{
											minWidth: '10em',
											minHeight: '38px',
											padding: '10px',
										}}
										value={phoneAreaCode}
										onChange={(e, { value }) => {
											formChanged.current = true;
											setAreaPhoneCode(value);
										}}
									/>
								</div>
								<div
									className='form-group col-lg-8 col-md-6 col-sm-12 col-12'
									style={{ marginTop: '10px' }}
								>
									<input
										type='number'
										onKeyDown={(event) => {
											if (
												!allowedKeys.includes(event.key) &&
												(isNaN(Number(event.key)) || event.key === ' ')
											) {
												event.preventDefault();
											}
										}}
										className='form-control'
										// style={{ height: '44px' }}
										name='phoneNumber'
										value={phoneNumber}
										onChange={(event) =>
											setPhoneNumber(maxLength(event.target.value, 20))
										}
										required
									/>
								</div>
							</div>
						</div>
						</div>
					</div>
					<div className='row'>
						{/* <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
							<label className='form-label'>{intl.gender}</label>
							<div className='form-group'>
								<span className='GenderGroup'>
									<Dropdown
										options={genderOptions}
										required
										selection
										style={{ width: '100%' }}
										placeholder='Gender'
										value={gender}
										onChange={(e, { value }) => {
											formChanged.current = true;
											setGender(value);
										}}
									/>
								</span>
							</div>
						</div> */}
						{/* {badgeNumber && badgeNumber != '' ?
						(<div className='col-lg-6 col-md-6 col-sm-12 col-12 d-flex'>
						<label style={{minWidth:"9em"}} className='form-label'>
									{intl.sendNotification}
									</label>
							<div className="switch">
									
									<label className='form-label'>
										
										<input type="checkbox" 
										className="toggle-input"
										onChange={handleSendNotificationSwitchChange}
										checked = {sendNotification}
										/>
										<span className="lever"></span>
							</label>
							</div>
						</div>)
						: ""
					} */}
					</div>

					<div className='row'>
						<div className='col-lg-6 col-md-6 col-sm-12 col-12'>
							<div className='row'>
								<div className='col-lg-6 col-md-6 col-sm-12 col-12'>
									<label className='form-label'>{intl.badgeNumber}</label>
									<div className='form-group d-flex'>
										<span
											className={`${
												badgeNumber !== null && badgeNumber.value !== null
													? 'remove-badge'
													: null
											}`}
											style={{ width: '100%' }}
										>
											<AsyncPaginate
												value={badgeNumber}
												loadOptions={loadOptions}
												getOptionValue={(option) => option.name}
												getOptionLabel={(option) => option.name}
												onChange={(value) => {
													formChanged.current = true;
													value.value !== null && setBadgeNumber(value);
												}}
												isSearchable={false}
												placeholder='-- SELECT --'
												additional={{
													page: 1,
												}}
												styles={customStyles}
											/>
										</span>
										{badgeNumber && (
											<span
												className='unassign-badge-button'
												onClick={() => setBadgeNumber(null)}
											>
												<FaTimesCircle id='btnClear' className='cancel-icon' />
											</span>
										)}
									</div>
								</div>
								{badgeNumber && badgeNumber !== '' ? (
									<div className='col-lg-4 col-md-4 col-sm-12 col-12 d-flex align-items-center'>
										<label style={{ minWidth: '9em' }} className='form-label'>
											{intl.sendNotification}
										</label>
										<div className='switch'>
											<label className='form-label'>
												<input
													type='checkbox'
													className='toggle-input'
													onChange={() => {
														formChanged.current = true;
														handleSendNotificationSwitchChange();
													}}
													checked={sendNotification}
												/>
												<span className='lever'></span>
											</label>
										</div>
									</div>
								) : (
									''
								)}
							</div>
						</div>
						<div className='col-lg-12 col-md-12 col-sm-12 col-12 mt-4'>
							<label className=''>{intl.assignedStudents} :</label>
							<div className='switch'>
								<label style={{ minWidth: '10em', fontWeight: 400 }}>
									{intl.receiveNotification}
								</label>
								<label className='form-label'>
									<input
										type='checkbox'
										className='toggle-input'
										onChange={() => {
											formChanged.current = true;
											handleReceiveNotificationSwitchChange();
										}}
										checked={receiveNotification}
									/>
									<span className='lever'></span>
								</label>
							</div>
						</div>
					</div>
					<div className='row mt-3 mb-3'>
						<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
							<div className='float_right '>
								<div align='center'>
									<button
										className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
										type='submit'
									>
										{loading2 ? <Spinner size='sm' /> : Strings.submitButton}
									</button>
								</div>
							</div>

							<div className='float_right mr-3'>
								{/* <span className='btn btn-secondary cancel-btn button-fixed hover-pointer'>
									<span onClick={() => history.push('/responder')}>
										{intl.cancelButton}
									</span>
								</span> */}
								<span
									className='btn btn-secondary cancel-btn button-fixed'
									onClick={(event) => {
										event.preventDefault();
										history.push('/responder');
										logEvent({
											category: Categories.staff,
											action: Actions.staff_edit_cancel_btn,
										});
									}}
								>
									{intl.cancelButton}
								</span>
							</div>
						</div>
					</div>
				</form>
			</div>
			{showUnsavedModal ? (
				<AlertModal
					closeModal={closeUnsavedModal}
					header={Strings.unsavedChanges}
					body={Strings.unsavedChangesText}
					handlerFunction={handleNavigationConfirmation}
				/>
			) : null}
			{showModal2 ? <LogoutModal /> : null}
			<ToastContainer />
		</div>
	);
};

export default withRouter(StaffDetails);
