import countries from 'i18n-iso-countries';
import { tz } from 'moment-timezone';
import { phoneCodes } from './phoneCodes';
const ct = require('countries-and-timezones');

 export const checkInternetAvailability = () => {
    if (navigator.onLine) {
       //console.log('Internet connection is available');
        return true;
    } else {
      //console.log('Internet connection is not available');
      return false;
     }
  };
  
  export const checkIsEmailValid = (email) => {
   const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;//https://stackoverflow.com/questions/52456065/how-to-format-and-validate-email-node-js
	if(emailRegexp.test(email)===false){
      return false;
   }
   return true;
  }
  
  export const guessCountryCode = () => {
    const timezoneData = ct.getTimezone(tz.guess());
    let countryCode =
      timezoneData.countries.length > 0 ? timezoneData.countries[0] : null;
    countryCode = countryCode ? countries.alpha2ToAlpha3(countryCode) : null;
    if (countryCode) {
      for (const code of phoneCodes) {
        if (code.text.endsWith(' ' + countryCode)) {
          return code.value;
        }
      }
      return false;
    }
  }

 export const getExpiryColor = (expiryDate) => {
      if (!expiryDate) {
         return '';
      }
      const today = new Date();
      const expiry = new Date(expiryDate);
      const differenceInMinutes = Math.floor((expiry - today) / (1000 * 60));
      if (differenceInMinutes <= 0) {
      return 'red-text';
      } else if (differenceInMinutes <= 14400) { // 10 days * 24 hours/day * 60 minutes/hour
      return 'yellow-text';
      }
      return '';
  };

  export const getCameraForQrScan = () => {
   return new Promise((resolve, reject) => {
     navigator.mediaDevices
       .enumerateDevices()
       .then((devices) => {
         const hasCamera = devices.some((device) => device.kind === "videoinput");
         if (!hasCamera) {
           reject("No Camera device Found for QR Scan");
           return;
         }
 
         navigator.permissions
           .query({ name: "camera" })
           .then((result) => {
             if (result.state === "granted") {
               resolve(true);
             } else {
               navigator.mediaDevices
                 .getUserMedia({ video: true })
                 .then(() => {
                   resolve(true);
                 })
                 .catch((error) => {
                   reject("Could not Get Camera permission for QR Scan");
                 });
             }
           })
           .catch((error) => {
             console.log(error);
             reject("Error Getting Camera permission for QR Scan");
           });
       })
       .catch((error) => {
         reject("No Camera device Found for QR Scan");
       });
   });
 };