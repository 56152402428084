import React, { useState, useEffect, useRef } from 'react';
// 
import { withRouter } from 'react-router-dom';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import intl from '../../locale/en-US.json';
import apiInstance from '../../api';
import { FaTimesCircle } from 'react-icons/fa';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { phoneCodes } from '../../utils/phoneCodes';
import Strings from '../../locale/en-US.json';
import { maxLength} from '../../utils/apiUtils';
import { Spinner } from 'react-bootstrap';
import { Dropdown } from 'semantic-ui-react';
import { PHONENO_ERROR, LENGTH_MAX } from '../../strings';
import AlertModal from '../Modal/AlertModal';
import { AsyncPaginate } from 'react-select-async-paginate';
import { errorFunction } from '../../utils/errors';
import LogoutModal from '../Modal/LogoutModal';
import useInputFocus from '../../utils/customHooks/useInputFocus';
import { logEvent,Categories,Actions } from "../../analytics";
import { guessCountryCode } from '../../utils/common';
const MAX_LENGTH = 255;
const MIN_LENGTH = 3;

const AddStaff = ({ history, props }) => {
	// const [macprefixId, setMacprefixId] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [isNotificaitonStatusChanged, setIsNotificaitonStatusChanged] = useState(false);
	// const [activeEnable, setActiveEnable] = useState({
	// 	leftEnable: 'statusButtonItemGrp button left active',
	// 	rightEnable: 'statusButtonItemGrp button right',
	// });
	const [errorMessage, setErrorMessage] = useState('');
	const gender='Male';
	const [phoneAreaCode, setAreaPhoneCode] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [badgeNumber, setBadgeNumber] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showModal2, setShowModal2] = React.useState(false);
	const [showModal, setShowModal] = useState(false);
	// const [recieveNotification, setRecieveNotification] = useState(true);
	const formChanged = useRef(false);
	const [sendNotification, setSendNotification] = useState(true);
	const [receiveNotification, setReceiveNotification] = useState(true);
	let nextInHistoryRef = useRef('');
	let allowNavigationRef = useRef(false);
	const isAnyInputFocused = useInputFocus();

	useEffect(() => {
		const handleBeforeUnload = (e) => {
			if (!checkUnsavedChanges()) {
				e.preventDefault();
				e.returnValue =
					'You have unsaved changes. Are you sure you want to leave this page?';
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	const closeModal = () => {
		setShowModal(false);
		//logEvent({category:Categories.staff,action:Actions.staff_add_cancel_changes_btn});
	}

	const navigationBlockCheck = (nextLocation) => {
		nextInHistoryRef.current = nextLocation && nextLocation.pathname;
		if (allowNavigationRef.current) {
			return true;
		}
		if (checkUnsavedChanges()) {
			return true;
		} else {
			setShowModal(true);
			return false;
		}
	};

	const handleSendNotificationSwitchChange = () => {
		setSendNotification(!sendNotification);
		formChanged.current = true;
		setIsNotificaitonStatusChanged(true);
	};

	const handleReceiveNotificationSwitchChange = () => {
		setReceiveNotification(!receiveNotification);
		formChanged.current = true;
		setIsNotificaitonStatusChanged(true);
	};

	const checkUnsavedChanges = () => {
		if (!formChanged.current) {
			return true;
		}
		return false;
	};

	const handleNavigationConfirmation = () => {
		setShowModal(false);
		allowNavigationRef.current = true;
		history.push(nextInHistoryRef.current);
	};

	useEffect(() => {
		let detectedCountryCode = guessCountryCode();
		detectedCountryCode && setAreaPhoneCode(detectedCountryCode);

		const unblock = history.block(navigationBlockCheck);

		return () => {
			unblock();
		};
	}, [history]);

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
				event.preventDefault(); // Prevent default undo behavior when no input is focused
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isAnyInputFocused]);

	// const genderOptions = [
	// 	{ key: 1, text: 'Male', value: 'Male' },
	// 	{ key: 2, text: 'Female', value: 'Female' },
	// 	{ key: 3, text: 'Other', value: 'Other' },
	// ];

	
	const showError = (result) => {
		setErrorMessage(result);
		if (result) {
			window.scrollTo(0, 0);
		}
	};

	const customStyles = {
		menu: (provided, state) => ({
			...provided,
			top: 'auto',
			bottom: '100%',
		}),
		control: (provided, state) => ({
			...provided,
			borderRadius: '5px',
			// border: '0.5px solid grey',
			boxShadow: state.isFocused ? '0 0 0 0.2px grey' : null,
			'&:hover': {
				border: '1px solid grey',
			},
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isFocused ? 'rgba(0,0,0,.03)' : null,
			color: 'black',
			fontWeight: state.isSelected ? 'bold' : null,
		}),
	};

	const loadOptions = async (searchQuery, loadedOptions, { page }) => {
		try {
			const result = await apiInstance.get(
				`/api/v1/badges?page=${page}&limit=200`
			);
			const unassignBadges = result.data.data.badges.filter(
				(badge) => badge.user.role === undefined
			);

			let options;
			if (page === 1 && unassignBadges.length === 0) {
				options = [{ name: 'No badges available', value: null }];
			} else {
				options = unassignBadges.map((badge, i) => ({
					name: badge.mac_address,
					value: badge._id,
				}));
			}

			return {
				options,
				hasMore: unassignBadges.length >= 1,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			};
		} catch (error) {
			const result = errorFunction(error);

			//console.log('result-------- ', result);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				showError(result);
			}
		}
	};

	const handleFormChange = async (event) => {
		const { type } = event.target;
		if (type === 'checkbox') {
			return;
		}
		event.preventDefault();
		if (event.nativeEvent && event.nativeEvent.inputType) {
			formChanged.current = true;
		}
	};

	const handleSubmit = async (event) => {
		logEvent({category:Categories.staff,action:Actions.staff_add_submit_btn});
		event.preventDefault();
		showError('');
		if (
			firstName.trim().length < MIN_LENGTH ||
			firstName.trim().length > MAX_LENGTH
		) {
			showError(Strings.firstName + ' : ' + LENGTH_MAX);
			return;
		}

		if (
			lastName.trim().length < MIN_LENGTH ||
			lastName.trim().length > MAX_LENGTH
		) {
			showError(Strings.lastName + ' : ' + LENGTH_MAX);
			return;
		}

		const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		if (!email.match(emailRegex)) {
			showError('Invalid Email format');
			return false;
		}
		const atIndex = email.indexOf('@');
		const lastCharBeforeAt = email[atIndex - 1];
		const lastCharBeforeAtRegex = /^[a-zA-Z0-9]$/;
		if (!lastCharBeforeAt.match(lastCharBeforeAtRegex)) {
			showError('Invalid Email format');
			return false;
		}

		if (
			phoneAreaCode.split(' ')[0].split('').slice(1).join('').length +
				phoneNumber.length <
				8 ||
			phoneAreaCode.split(' ')[0].split('').slice(1).join('').length +
				phoneNumber.length >
				15
		) {
			showError(PHONENO_ERROR);
			return;
		}

		if (gender === '') {
			showError("Gender can't be empty");
			return;
		}

		if (phoneAreaCode === '') {
			showError('Select a phone area code');
			return;
		}

		try {
			let requestBody = {
				first_name: firstName.trim(),
				last_name: lastName.trim(),
				email: email,
				gender: gender,
				phone_no: `${phoneAreaCode.split(' ')[0]}-${phoneNumber}`,
				role: intl.roleTeacher,
				send_notification: sendNotification,
				receive_notification: receiveNotification
			};
			setFirstName(firstName.trim());
			setLastName(lastName.trim());

			if (badgeNumber !== null && badgeNumber.value !== null) {
				requestBody.badge_id = badgeNumber.value;
			}
			if (!submitted) {
				setLoading(true);
				setSubmitted(true);
				const result = await apiInstance.post('/api/v1/users/register', [
					requestBody,
				]);

				// console.log('Teacher result', result);
				if (result) {
					setLoading(false);
					if(isNotificaitonStatusChanged===true){
						logEvent({category:Categories.staff,action:Actions.staff_add_notification_status_changed,value:sendNotification});
	
					}
					allowNavigationRef.current = true;
					logEvent({category:Categories.staff,action:Actions.staff_add_submit_success});
					toast.success('Successfully Saved!', {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 2000, // Toast message will be shown for 2 seconds
					});
					setTimeout(() => {
						history.push('/responder');
					}, 2000);
				}
			}
		} catch (error) {
			setSubmitted(false);
			setLoading(false);
			const result = errorFunction(error);

			//console.log('result-------- ', result);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				showError(result);
			}
		}
	};

	return (
		<>
			<div>
				<Breadcrumb
					links={[
						{ link: '/responder', title: intl.staffList},
						{ link: '/addResponder', title: intl.addStaffButton },
						
					]}
					
				/>

				<div className='card-header card_headertitle'>
					<div className='card-title card_head'>
						<h4 className='card_head CardTitle text-uppercase component-heading  '>
							{intl.addStaffButton}
						</h4>
					</div>
					{errorMessage && (
						<div className='col-lg-12 col-md-12 col-sm-12 col-12 btn_error'>
							{errorMessage}
						</div>
					)}
				</div>

				<div className='card-body' style={{ background: '#fff' }}>
					{/* <div className="d-flex justify-content-center"> */}
					{/* {loading  ? <Spinner /> : null} */}
					{/* </div> */}
					<form id='PersonSaveForm' onChange={handleFormChange} onSubmit={handleSubmit} action=''>
						<div className='row'>
							<div className='col-lg-6 col-md-12 col-sm-12 col-12'>
								<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
									<div className='form-group'>
										<label className='form-label'>
											{intl.firstName}
											<span className='font_red'>*</span>
										</label>
										<input
											type='text'
											className='form-control'
											name='FirstName'
											value={firstName}
											onChange={(event) =>
												setFirstName(maxLength(event.target.value, 50))
											}
											required
											autoFocus
										/>
									</div>
								</div>
								<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
									<div className='form-group'>
										<label className='form-label'>
											{intl.lastName}
											<span className='font_red'>*</span>
										</label>
										<input
											type='text'
											className='form-control'
											name='LastName'
											value={lastName}
											onChange={(event) =>
												setLastName(maxLength(event.target.value, 50))
											}
											required
										/>
									</div>
								</div>
								<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
									<div className='form-group'>
									<label className='form-label'>
										{intl.email}
										<span className='font_red'>*</span>
										<span style={{ fontSize: '10px', fontWeight: '150', marginLeft: '5px' }}>
											({intl.emailReasonMessage})
										</span>
									</label>
										<input
											type='email'
											className='form-control'
											name='Email'
											value={email}
											onChange={(event) =>
												setEmail(maxLength(event.target.value, 50))
											}
											required
										/>
									</div>
								</div>
								{/* <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
									<label className='form-label'>
										{intl.gender}
										<span className='font_red'>*</span>
									</label>
									<div className='form-group'>
										<span className='GenderGroup'>
											<Dropdown
												options={genderOptions}
												required
												selection
												placeholder='Gender'
												value={gender}
												onChange={(e, { value }) => setGender(value)}
											/>
										</span>
									</div>
								</div> */}
								<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
									<label className='form-label'>
										{Strings.phoneNo}
										<span className='font_red'>*</span>
										<span style={{ fontSize: '10px', fontWeight: '150', marginLeft: '5px' }}>
											({intl.smsReasonMessage})
										</span>
									</label>
								</div>
								<div className='row'>
									<div className='form-group col-lg-4 col-md-4 col-sm-12 col-12 ml-3'>
										<Dropdown
											options={phoneCodes}
											selection
											placeholder='Code'
											search
											style={{ minWidth: '10em' }}
											value={phoneAreaCode}
											onChange={(e, { value }) => {
												setAreaPhoneCode(value);
											}}
										/>
									</div>
									<div className='form-group col-lg-7 col-md-7 col-sm-12 col-12 ml-3'>
										<input
											type='text'
											className='form-control'
											style={{ width: '95%' }}
											name='phoneNumber'
											onKeyDown={(event) => {
												const allowedKeys = [
													'Backspace',
													'Delete',
													'ArrowLeft',
													'ArrowRight',
													'Tab',
												];

												if (
													!allowedKeys.includes(event.key) &&
													(isNaN(Number(event.key)) || event.key === ' ')
												) {
													event.preventDefault();
												}
											}}
											value={phoneNumber}
											onChange={(event) =>
												setPhoneNumber(maxLength(event.target.value, 20))
											}
											required
										/>
									</div>
								</div>

								<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
									<label className='form-label'>{intl.badgeNumber}</label>
									<div className='form-group d-flex'>
										<span
											className={badgeNumber && 'remove-badge'}
											style={{ width: '100%' }}
										>
											<AsyncPaginate
												value={badgeNumber}
												loadOptions={loadOptions}
												getOptionValue={(option) => option.name}
												getOptionLabel={(option) => option.name}
												onChange={(value) => {
													value.value !== null && setBadgeNumber(value);
												}}
												isSearchable={false}
												placeholder='-- SELECT --'
												additional={{
													page: 1,
												}}
												styles={customStyles}
											/>
										</span>
										{badgeNumber && (
											<span
												className='unassign-badge-button'
												onClick={() => setBadgeNumber(null)}
											>
												<FaTimesCircle id='btnClear' className='cancel-icon' />
											</span>
										)}
									</div>
								</div>

								{/* {badgeNumber && (
										<div className='col-lg-1 col-md-1 col-sm-1 col-1'>
											<FaTimesCircle
												id='btnClear'
												style={{ cursor: 'pointer' }}
												
												className='unassign-badge-button'
											></FaTimesCircle>
										</div>
									)} */}
								{badgeNumber ? (
									<div className='col-lg-12 col-md-12 col-sm-12 col-12 mt-4'>
										<div className='switch'>
											<label
												style={{ minWidth: '12em' }}
												className='form-label'
											>
												{intl.sendNotification}
											</label>
											<label className='form-label'>
												<input
													type='checkbox'
													className='toggle-input'
													onChange={handleSendNotificationSwitchChange}
													checked={sendNotification}
												/>
												<span className='lever'></span>
											</label>
										</div>
									</div>
								) : (
									''
								)}
								<div className='col-lg-12 col-md-12 col-sm-12 col-12 mt-4'>
									<div className='switch'>
										<label
											style={{ minWidth: '12em' }}
											className='form-label'
										>
											{intl.receiveNotification}
										</label>
										<label className='form-label'>
											<input
												type='checkbox'
												className='toggle-input'
												onChange={handleReceiveNotificationSwitchChange}
												checked={receiveNotification}
											/>
											<span className='lever'></span>
										</label>
									</div>
								</div>
								<div
									className='col-lg-6 col-md-12 col-sm-12 col-12'
									id='LocationMapping'
								>
									<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
										<div className='LocationsDiv'></div>
									</div>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
								<div className='float_right mr-3'>
									<div align='center'>
										<button
											className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
											type='submit'
										>
											{loading ? <Spinner size='sm' /> : Strings.submitButton}
										</button>
									</div>
								</div>
								<div className='float_right mr-3'>
									<span
										className='btn btn-secondary cancel-btn button-fixed'
										onClick={(event) => {
											logEvent({category:Categories.staff,action:Actions.staff_add_cancel_btn});
											event.preventDefault();
											history.push('/responder');
										}}
									>
										{intl.cancelButton}
									</span>
								</div>
							</div>
						</div>
					</form>
					{showModal ? (
						<AlertModal
							closeModal={closeModal}
							header={Strings.unsavedChanges}
							body={Strings.unsavedChangesText}
							handlerFunction={handleNavigationConfirmation}
						/>
					) : null}
				</div>
				{showModal2 ? <LogoutModal /> : null}
				<ToastContainer />
			</div>
		</>
	);
};
export default withRouter(AddStaff);
