import React from 'react';
import { withRouter } from 'react-router-dom';
import AlertModal from '../Modal/AlertModal';
import intl from '../../locale/en-US.json';
import apiInstance from '../../api';
import { Categories, logEvent, Actions } from "../../analytics";
import {
	clearLocalStorageLists,
	getUserBlockedStore,
	removeAccessTokenInStore,
	removeRoleInStore,
	getBlockedByAdminStore,
	removeApiLogInStore,
	removeRenewAccessTokenInProgress,
	getRoleInStore,
} from '../../utils/apiUtils';

const MenuItemsDisplay = (props) => {
	const { history } = props;
	const [showModal, setShowModal] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	const closeModal = () => {
		setShowModal(false);
		logEvent({category:Categories.authentication,action:Actions.signout_cancel_btn,label:getRoleInStore()});;
	};

	const handleLogout = async () => {
		setLoading(true);
		removeApiLogInStore();
		removeRenewAccessTokenInProgress();
	    logEvent({category:Categories.authentication,action:Actions.signout_confirm_btn});	
		try {
			await apiInstance.post('/api/v1/auth/logout');
			setShowModal(false);
			setLoading(false);
		} catch (err) {
			console.log('Logout error');
			setShowModal(false);
			setLoading(false);
		}
		localStorage.clear();
		removeAccessTokenInStore();
		removeRoleInStore();

		props.history.push('/login');
	};

	const cursorstyle = () => {
		if (getBlockedByAdminStore()) {
			if (
				props.item.item.url === '/contactus' ||
				props.item.item.name === 'Sign Out'
			) {
				return 'pointer';
			} else {
				return 'no-drop';
			}
		}
		if (getUserBlockedStore()) {
			if (
				props.item.item.url === '/contactus' ||
				props.item.item.url === '/organizationSettings' ||
				props.item.item.url === '/settings' ||
				props.item.item.name === 'Sign Out'
			) {
				return 'pointer';
			} else {
				return 'no-drop';
			}
		}
	};

	return (
		<>
			{showModal ? (
				<AlertModal
					closeModal={closeModal}
					header={intl.logoutHeader}
					body={intl.logoutBody}
					handlerFunction={handleLogout}
					loading={loading}
				/>
			) : null}

			<li
				className='nav-item sidebarTop list-removebullets'
				key={props.item.id}
			>
				<div className='selected' style={props.item.item.name === 'Sign Out' ? {marginBottom: '6em'} : null}>
					<span
						onClick={() => {
							clearLocalStorageLists(props.item.item.name);
							if (props.item.item.name === 'Sign Out') {
								props.item.toggleSidebar();
								logEvent({category:Categories.authentication,action:Actions.signout_btn});
								setShowModal(true);
							
							}
							 else {
								if (props.item.item.url ===  "/contactus") {
										logEvent({category:Categories.feedback,action:Actions.contact_us_btn,label:"sidebar"});
									}
									else if (props.item.item.url ===  "/settings") {
										logEvent({category:Categories.accountSettings,action:Actions.accountSettings_btn,label:"sidebar"});
									}
									else if (props.item.item.url ===  "/organizationSettings") {
										logEvent({category:Categories.school,action:Actions.schoolSettings_btn,label:"sidebar"});
									}
									else if (props.item.item.url ===  "/panelnotifications") {
										logEvent({category:Categories.alert,action:Actions.alert_btn});
									}
									else if (props.item.item.url ===  "/device") {
										logEvent({category:Categories.device,action:Actions.device_btn,label:"sidebar"});
									}
									else if (props.item.item.url ===  "/responder") {
										logEvent({category:Categories.staff,action:Actions.staff_btn,label:"sidebar"});
									}
									else if (props.item.item.url ===  "/users") {
										logEvent({category:Categories.student,action:Actions.student_btn,label:"sidebar"});
									}
									else if (props.item.item.url ===  "/plan") {
										logEvent({category:Categories.plan,action:Actions.plan_btn});
									}
									else if (props.item.item.url ===  "/organizations") {
										logEvent({category:Categories.school,action:Actions.school_btn});
									}
									else if (props.item.item.url ===  "/feedback") {
										logEvent({category:Categories.feedback,action:Actions.feedbacklist_btn});
									}
									else if (props.item.item.url ===  "/notifications") {
										logEvent({category:Categories.alert,action:Actions.alert_list_btn});
									}
									else if (props.item.item.url ===  "/badge") {
										logEvent({category:Categories.badge,action:Actions.badge_btn,label:"sidebar"});
									}
									else if (props.item.item.url ===  "/privacy") {
										logEvent({category:Categories.accountSettings,action:Actions.policies_info_btn,label:"sidebar"});
									}
									
								
								// normal
								if (!getUserBlockedStore() && !getBlockedByAdminStore()) {
									props.item.toggleSidebar();
									history.push(props.item.item.url);
								}
								// incase of blocked
								else if (getBlockedByAdminStore()) {
									if (props.item.item.url === '/contactus') {
										props.item.toggleSidebar();
										history.push(props.item.item.url);
									} else {
										props.item.toggleSidebar();
									}
								}
								// incase of non verified email
								else if (getUserBlockedStore()) {
									if (
										props.item.item.url === '/contactus' ||
										props.item.item.url === '/organizationSettings' ||
										props.item.item.url === '/settings'
									) {
										props.item.toggleSidebar();
										history.push(props.item.item.url);
									} else {
										props.item.toggleSidebar();
									}
								}
							}
						}}
					>
						<span
							className='nav-link waves-effect waves-dark active'
							style={{ cursor: cursorstyle() }}
						>
							<img
								src={props.item.item.src}
								alt='icon'
								style={{ height: '24px', width: '24px' }}
							/>
							<span
								className={
									'sidebar-list-txt ' +
									(window.location.pathname === props.item.item.url
										? 'selectedColor'
										: '')
								}
							>
								{props.item.item.name}
							</span>
						</span>
					</span>
				</div>
			</li>
		</>
	);
};
export default withRouter(MenuItemsDisplay);
