import React, {useState, useRef, useEffect} from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import intl from "../../locale/en-US.json";
import * as XLSX from 'xlsx';
import { read, utils } from "xlsx";
import Papa from "papaparse";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { errorFunction } from '../../utils/errors';
import apiInstance from "../../api";
import LogoutModal from './LogoutModal';
import { DEVICENAME_LENGTH_ERROR, DEVICENO_LENGTH_ERROR, PHONENO_ERROR, PHONECODE_ERROR, LENGTH_MAX, MISSING_FIELD, EMPTY_FILE, INVALID_DEVICE_ID, INVALID_DEVICE_ID_FORMAT } from '../../strings';
import Strings from '../../locale/en-US.json';
import { Actions, Categories, logEvent } from "../../analytics";
import {tz} from 'moment-timezone';
import sampleData from '../../utils/BulkSampleData'
import { phoneCodes } from '../../utils/phoneCodes';
const DEVICENAME_MIN_LENGTH = 3;
const DEVICENAME_MAX_LENGTH = 50;
const DEVICENO_MIN_LENGTH = 12;
const DEVICENO_MAX_LENGTH = 20;
const MAX_LENGTH = 255;
const MIN_LENGTH = 3;
const MAX_FILESIZE = 5 * 1024 * 1024; // 5MB


const BulkAddModal = ({ closeModal, header, type, columnNames, route}) => {
    const fileInputRef = useRef(null);    
    const [processLoading, setProcessLoading] = useState(false);
    const [showModal2, setShowModal2] = useState(false); //for logout modal
    const [submitLoading, setSubmitLoading] = useState(false);
    const [processed, setProcessed] = useState(false);
    const [data, setData] = useState([]); 
    const [submitted, setSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [cancelTokenSource, setCancelTokenSource] = useState(null);
    const [totalRecords,setTotalRecords] = useState(0);
    
    useEffect(() => {
        return () => {
          // Clean up the cancelTokenSource when the component unmounts
          if (cancelTokenSource) {
            cancelTokenSource.cancel('Component unmounted');
            setCancelTokenSource(null);
          }
        };
    }, []);

    const changeHandler = (event) => {
        if (!event.target.files || event.target.files.length === 0) {
            // User canceled, do nothing
            setProcessed(false);
            return;
        }
        setErrorMessage("");
        setProcessLoading(true);
        setProcessed(false);
        let excel = false;
		const file = event.target.files[0];
        const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
        
        // checks for csv file
        if (file && file.type === 'text/csv') {
            if (file.size > MAX_FILESIZE) {
                setErrorMessage(Strings.maxSizeErrorMessage);
                setProcessLoading(false);
                return;
            }
        }
        // checks for excel file
        else if (file && allowedTypes.includes(file.type)) {
            if (file.size > MAX_FILESIZE) {
                setErrorMessage(Strings.maxSizeErrorMessage);
                setProcessLoading(false);
                return;
            }
            excel = true;
        }
        else {
            setErrorMessage(Strings.fileTypeErrorMessage);
            setProcessLoading(false);
            return;
        }

        let csvText;
        if (excel) {
            // in excel case
            convertToCsv(file)
                .then((result) => {
                    // Get the csv file after excel conversion
                    csvText = result.csvData
                    parseCsvFile(csvText, result.header);
                })
                .catch((error) => {
                    setErrorMessage(Strings.processingErrorMessage);
                    setProcessLoading(false);
                });
        } else {
            // in csv case
            parseCsvFile(file)
        }
        if(type ==='staff'){
            logEvent({category:Categories.staff,action:Actions.staff_bulk_upload_choose_file_download_btn});
        }
        else if(type ==='student'){
            logEvent({category:Categories.student,action:Actions.student_bulk_upload_choose_file_download_btn});
        }
        else if(type ==='badge'){
            logEvent({category:Categories.badge,action:Actions.badge_bulk_upload_choose_file_download_btn});
        }
        else if(type ==='device'){
            logEvent({category:Categories.device,action:Actions.device_bulk_upload_choose_file_download_btn});
        }
	};

    const parseCsvFile = (file, header) => {
        const bulkData = [];
        let lineNumber = 1;
        setTotalRecords(1);
        let parsingError = false;
        const Timezone = tz.guess();
        const TimezoneCode = tz(tz.guess()).zoneAbbr();
        const config = {
            delimiter: ',',
            header: true,
            skipEmptyLines: true,
            step: (results, parser) => {     
                try{  
                if (parsingError) {
                    return;
                }
                
                // Track the row number.
                if (!parser._rowNumber) {
                    parser._rowNumber = 1;
                } else {
                    parser._rowNumber++;
                }

                if (parser._rowNumber === 1) {
                    const actualColumns = header ? header : results.meta.fields; // column names in csv file
                    let columnsMatch;
                    if (actualColumns.length > columnNames.length) {
                        columnsMatch = actualColumns.every((col) => columnNames.includes(col));
                    } else {
                        columnsMatch = columnNames.every((col) => actualColumns.includes(col));
                    }

                    if (!columnsMatch) {
                        setErrorMessage(Strings.headersMismatch);
                        parsingError = true;
                        setProcessLoading(false);
                        return;
                    }
                }
                if (lineNumber === 5000) { 
                    setErrorMessage(Strings.maxEntriesMismatch);
                    setProcessLoading(false);
                    parsingError = true;
                    return;
                }
                switch (type) {
                    case 'device':
                        lineNumber ++;
                        if (results.data.device_name && results.data.device_number && results.data.device_type) {
                            results.data.timezone = Timezone
                            results.data.timezone_code = TimezoneCode
                        }
                        else {
                            setErrorMessage(MISSING_FIELD + Strings.onLineNumber + lineNumber);
                            parsingError = true;
                            return;
                        }
                        if (
                            results.data.device_name.trim().length < DEVICENAME_MIN_LENGTH ||
                            results.data.device_name.trim().length > DEVICENAME_MAX_LENGTH
                        ) {
                            setErrorMessage(intl.deviceName + ' : ' + DEVICENAME_LENGTH_ERROR +Strings.onLineNumber + lineNumber);
                            parsingError = true;
                            return;
                        } 
                        if (
                            !results.data.device_number.trim().includes("'")
                           
                        ) {
                            setErrorMessage(intl.deviceNumberInvalid + ' : ' + INVALID_DEVICE_ID_FORMAT + Strings.onLineNumber + lineNumber);
                            parsingError = true;
                            return;
                        }
                        else{
                            results.data.device_number = results.data.device_number.replace(/'/g, '');
                        }
                        if (
                            results.data.device_number.trim().toLowerCase() === INVALID_DEVICE_ID
                           
                        ) {
                            setErrorMessage(intl.deviceNumberInvalid + ' : ' + INVALID_DEVICE_ID + Strings.onLineNumber + lineNumber);
                            parsingError = true;
                            return;
                        }
                        if (
                            results.data.device_number.trim().length < DEVICENO_MIN_LENGTH ||
                            results.data.device_number.trim().length > DEVICENO_MAX_LENGTH
                        ) {
                            setErrorMessage(intl.deviceNumber + ' : ' + DEVICENO_LENGTH_ERROR+ Strings.onLineNumber + lineNumber);
                            parsingError = true;
                            return;
                        }
                        if (results.data.device_type !== 'Panel' && results.data.device_type !== 'Gateway' 
                            && results.data.device_type !== 'panel' && results.data.device_type !== 'gateway')
                        {
                            setErrorMessage("Invalid "+ intl.deviceType + Strings.onLineNumber + lineNumber);
                            parsingError=true;
                            return;
                        }
                        results.data.device_type = results.data.device_type.slice(0,1).toUpperCase() +
                            results.data.device_type.slice(1, results.data.device_type.length);
                        bulkData.push(results.data);
                        break;
                    case 'badge':
                        if(results.data.mac_address) {
                            lineNumber ++;
                        }
                        else {
                            setErrorMessage(MISSING_FIELD + Strings.onLineNumber + lineNumber);
                            parsingError = true;
                            return;
                        }
                        if (results.data.mac_address.trim().length < 12 || results.data.mac_address.trim().length > 12) {
                            setErrorMessage(Strings.validMacAddress + lineNumber + ".");
                            parsingError = true;
                            return;
                        }
                        bulkData.push({mac_address: results.data.mac_address.toLocaleUpperCase()});
                        break;
                    case 'staff':
                        lineNumber ++;
                        if (results.data.first_name && results.data.last_name && results.data.email && results.data.phone_no) {
                        }
                        else {
                            setErrorMessage(MISSING_FIELD +  Strings.onLineNumber + lineNumber);
                            parsingError = true;
                            return;
                        }
                        if (results.data.first_name.trim().length < MIN_LENGTH || results.data.first_name.trim().length > MAX_LENGTH) {
                            setErrorMessage(Strings.firstName + ' : ' + LENGTH_MAX+ Strings.onLineNumber + lineNumber);
                            parsingError = true;
                            return;
                        }
                
                        if (results.data.last_name.trim().length < MIN_LENGTH || results.data.last_name.trim().length > MAX_LENGTH) {
                            setErrorMessage(Strings.lastName + ' : ' + LENGTH_MAX+ Strings.onLineNumber + lineNumber);
                            parsingError = true;
                            return;
                        }
                
                        const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                        if (!results.data.email.match(emailRegex)) {
                            setErrorMessage(Strings.invalidEmailOnLine + lineNumber);
                            parsingError = true;
                            return false;
                        }
                
                        const atIndex = results.data.email.indexOf('@');
                        const lastCharBeforeAt = results.data.email[atIndex - 1];
                        const lastCharBeforeAtRegex = /^[a-zA-Z0-9]$/;
                        if (!lastCharBeforeAt.match(lastCharBeforeAtRegex)) {
                            setErrorMessage(Strings.invalidEmailOnLine + lineNumber);
                            parsingError = true;
                            return false;
                        }

                        if ( results.data.phone_no && results.data.phone_no.includes('(') && results.data.phone_no.includes(')')) {
                            results.data.phone_no = results.data.phone_no.replace('(','+')
                            results.data.phone_no = results.data.phone_no.replace(')','-') 
                        } else {
                            setErrorMessage(PHONENO_ERROR +" line number "+ lineNumber);
                            parsingError = true;
                            return;
                        }

                        if ( results.data.phone_no.length - 2 < 9 || results.data.phone_no.length - 2 > 15) {
                            setErrorMessage(PHONENO_ERROR +" line number "+ lineNumber);
                            parsingError = true;
                            return;
                        }

                        // code checking
                        const validCode = phoneCodes.some(code => code.text.split(' ')[0].slice(1) === results.data.phone_no.split('-')[0].slice(1))
                        if (!validCode) {
                            setErrorMessage(PHONECODE_ERROR +" line number "+ lineNumber);
                            parsingError = true;
                            return;
                        }

                        // if (!results.data.gender) {
                        //     setErrorMessage(Strings.genderEmptyMessage +" "+ lineNumber );
                        //     parsingError = true;
                        //     return;
                        // }
                        
                        // if (!['Male', 'Female', 'male', 'female', 'MALE', 'FEMALE', 'other', 'Other', 'OTHER'].includes(results.data.gender)) {
                        //     // results.data.gender !== "Male" && results.data.gender !== "Female" && results.data.gender !== "Other"
                        //     setErrorMessage(Strings.genderErrorMessage + lineNumber );
                        //     parsingError = true;
                        //     return;
                        // }
                        // results.data.gender = results.data.gender.toLowerCase();
                        // results.data.gender = results.data.gender.charAt(0).toUpperCase() + results.data.gender.slice(1);

                        if (results.data.phone_no.split === '') {
                            setErrorMessage(Strings.areaCodeError + lineNumber);
                            parsingError = true;
                            return;
                        }

                        if (results.data.mac_address && (results.data.mac_address.length < 12 || results.data.mac_address.length > 12)) {
                            setErrorMessage(Strings.validMacAddress + lineNumber);
                            parsingError = true;
                            return;
                        }
 
                        if (results.data.receive_notification && !['true', 'false', 'TRUE', 'FALSE', 'True', 'False'].includes(results.data.receive_notification)) {
                            setErrorMessage(Strings.receiveNotificationError + lineNumber);
                            parsingError = true;
                            return;
                        }
                        else {
                            results.data.receive_notification = results.data.receive_notification.toLowerCase() === 'false' ? false : true;
                        }

                        if (results.data.send_notification && !['true', 'false', 'TRUE', 'FALSE', 'True', 'False'].includes(results.data.send_notification)) {
                            setErrorMessage(Strings.sendNotificationError + lineNumber);
                            parsingError = true;
                            return;
                        } else {
                            results.data.send_notification = results.data.send_notification.toLowerCase()  === 'false' ? false : true
                        }

                        results.data.gender = "Male";
                        results.data.role = intl.roleTeacher;
                        if (results.data.mac_address === "") delete results.data['mac_address'];
                        bulkData.push(results.data);
                        break;
                    case 'student':
                        lineNumber ++;
                        if (results.data.first_name && results.data.last_name) {
                        }
                        else {
                            setErrorMessage(MISSING_FIELD +  " on Entry number " + lineNumber);
                            parsingError = true;
                              return;
                        }
                        if (results.data.first_name.trim().length < MIN_LENGTH || results.data.first_name.trim().length > MAX_LENGTH) {
                            setErrorMessage(Strings.firstName + ' : ' + LENGTH_MAX + Strings.onLineNumber + lineNumber);
                            parsingError = true;
                            return;
                        }
                
                        if (results.data.last_name.trim().length < MIN_LENGTH || results.data.last_name.trim().length > MAX_LENGTH) {
                            setErrorMessage(Strings.lastName + ' : ' + LENGTH_MAX+ Strings.onLineNumber + lineNumber);
                            parsingError = true;
                            return;
                        }

                        // if (!results.data.gender) {
                        //     setErrorMessage(Strings.genderEmptyMessage +" "+ lineNumber );
                        //     parsingError = true;
                        //     return;
                        // }
                        
                        // if (!['Male', 'Female', 'male', 'female', 'MALE', 'FEMALE', 'other', 'Other', 'OTHER'].includes(results.data.gender)) {
                        //     // results.data.gender !== "Male" && results.data.gender !== "Female" && results.data.gender !== "Other"
                        //     setErrorMessage(Strings.genderErrorMessage + lineNumber );
                        //     parsingError = true;
                        //     return;
                        // }
                        
                        // results.data.gender = results.data.gender.toLowerCase();
                        // results.data.gender = results.data.gender.charAt(0).toUpperCase() + results.data.gender.slice(1);

                        if (results.data.mac_address && (results.data.mac_address.trim().length < 12 || results.data.mac_address.trim().length > 12)) {
                            setErrorMessage(Strings.validMacAddress + lineNumber);
                            parsingError = true;
                            return;
                        }

                        if (results.data.send_notification && !['true', 'false', 'TRUE', 'FALSE', 'True', 'False'].includes(results.data.send_notification)) {
                            setErrorMessage(Strings.sendNotificationError + lineNumber);
                            parsingError = true;
                            return;
                        } else {
                            results.data.send_notification = results.data.send_notification.toLowerCase()  === 'false' ? false : true
                        }

                        results.data.role = intl.roleStudent;
                        results.data.gender = "Male";
                        if (results.data.mac_address === "") delete results.data['mac_address'];
                        bulkData.push(results.data);
                        break;
                    default:
                        break;
                }
                setTotalRecords(lineNumber);
            }
            catch(error) {
                setErrorMessage("Error in Parsing file")
                console.log(error);
                parsingError = true;
                setProcessLoading(false);
                return;
            }
            },
            complete: (results, file) => {
                // when no entries in file
                try {
                if (bulkData.length === 0 && !parsingError) {
                    setProcessLoading(false);
                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    }
                    setErrorMessage(EMPTY_FILE);
                }
                // when data is correctly parsed
                else if (!parsingError) {
                    let hasDuplicates = false;
                    let seen = new Set();
                    let duplicateHeader=""
                    let duplicateValue=""
                    switch (type) {
                        case 'badge':
                            hasDuplicates =bulkData.some(function(currentObject) {
                                if (seen.has(currentObject.mac_address)) {
                                  hasDuplicates = true;
                                  duplicateValue = currentObject.mac_address;
                                  return true;
                                }
                                seen.add(currentObject.mac_address);
                                return false;
                            });
                            duplicateHeader = " mac_address"
                            seen.clear();
                            break;
                        case 'device':
                            hasDuplicates =bulkData.some(function(currentObject) {
                                if (seen.has(currentObject.device_number)) {
                                  hasDuplicates = true;
                                  duplicateValue = currentObject.device_number;
                                  return true;
                                }
                                seen.add(currentObject.device_number);
                                return false;
                            });
                            duplicateHeader = " device_number"
                            seen.clear();
                            break;
                        case 'student':
                            hasDuplicates =bulkData.some(function(currentObject) {
                                if (currentObject.hasOwnProperty("mac_address")) {
                                    if (seen.has(currentObject.mac_address)) {
                                    hasDuplicates = true;
                                    duplicateValue = currentObject.mac_address;
                                    return true;
                                    }
                                    seen.add(currentObject.mac_address);
                                }
                                return false;
                            });
                            duplicateHeader = "badge mac";
                            seen.clear();
                            break;
                        case 'staff':
                            hasDuplicates =bulkData.some(function(currentObject) {
                                if (currentObject.hasOwnProperty("email")) {
                                    if (seen.has(currentObject.email)) {
                                    hasDuplicates = true;
                                    duplicateValue = currentObject.email;
                                    return true;
                                    }
                                    seen.add(currentObject.email);
                                }
                                return false;
                            });
                            duplicateHeader = " email";
                            seen.clear();
                            if (!hasDuplicates) {
                                hasDuplicates =bulkData.some(function(currentObject) {
                                    if (currentObject.hasOwnProperty("phone_no")) {
                                        if (seen.has(currentObject.phone_no)) {
                                        hasDuplicates = true;
                                        duplicateValue = currentObject.phone_no;
                                        return true;
                                        }
                                        seen.add(currentObject.phone_no);
                                    }
                                    return false;
                                });
                            duplicateHeader = " phone_no";
                            }
                            seen.clear();
                            if (!hasDuplicates) {
                                hasDuplicates =bulkData.some(function(currentObject) {
                                    if (currentObject.hasOwnProperty("mac_address")) {
                                        if (seen.has(currentObject.mac_address)) {
                                        hasDuplicates = true;
                                        duplicateValue = currentObject.mac_address;
                                        return true;
                                        }
                                        seen.add(currentObject.mac_address);
                                    }
                                    return false;
                                });
                                duplicateHeader = "badge mac";
                            }
                            seen.clear();
                            break;
                        default:
                            break;
                    }
                    if (hasDuplicates) {
                        setErrorMessage('Duplicate '+ duplicateHeader + ' entries not allowed '+ duplicateValue);
                        if (fileInputRef.current) {
                            fileInputRef.current.value = '';
                        }
                        setProcessLoading(false);
                    }
                    else{
                        setData([...bulkData]);
                        setProcessLoading(false);
                        setProcessed(true)
                        setErrorMessage("");
                    }
                } 
                // in case of error
                else {
                    setProcessLoading(false);
                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    }
                }
                }
                catch(error) {
                    console.log(error);
                    setErrorMessage("Error in Processing File")
                }
            },
        };

        Papa.parse(file, config);
    }

    // Convert excel file to csv file
    const convertToCsv = (file) => {

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
        
            reader.onload = (e) => {
                try {
                    const data = new Uint8Array(e.target.result);
                    const workbook = read(data, { type: "array" });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];

                    // Extract header (first row)
                    const header = [];
                    const range = XLSX.utils.decode_range(sheet['!ref']);
                    for (let C = range.s.c; C <= range.e.c; ++C) {
                        const cell = sheet[XLSX.utils.encode_cell({ r: range.s.r, c: C })];
                        header.push(cell ? cell.v : undefined);
                    }

                    const csvData = Papa.unparse(utils.sheet_to_json(sheet, {
                        header: true,
                    }),
                    {   header: true,
                        columns: header}
                    );
                    const result = {
                        header: header,
                        csvData: csvData
                    };
                    resolve(result); }
                catch(error) {
                    reject(error); 
                    console.log(error)
                }
            };
        
            reader.onerror = (error) => {
                reject(error); 
            };
            reader.readAsArrayBuffer(file);
        });
    };

    
    const cancelRequest = () => {
        if (cancelTokenSource) {
            cancelTokenSource.cancel('Request canceled by user');
            setTotalRecords(0);
            closeModal();
        }

    };


    const submit = async () => {
        let cancelSource;
        if(type === 'staff'){
            logEvent({category:Categories.staff,action:Actions.staff_bulk_upload_submit_btn});
        }
        else if(type === 'device'){
            logEvent({category:Categories.device,action:Actions.device_bulk_upload_submit_btn});
        }
        else if(type === 'badge'){
            logEvent({category:Categories.badge,action:Actions.badge_bulk_upload_submit_btn});
        }
        else if(type === 'student'){
            logEvent({category:Categories.student,action:Actions.student_bulk_upload_submit_btn});
        }
        try {
            if (!submitted && errorMessage === "") {
                setSubmitLoading(true);
                setSubmitted(true);
                
                if (!cancelTokenSource) {
                    cancelSource = axios.CancelToken.source();
                    setCancelTokenSource(cancelSource);
                }
                route = `${route}?includeDetail=true`;
                const response = await apiInstance.post(route, data,{
                        cancelToken: cancelSource.token,
                    }
                )
                
                if (response && response.status === 200) {
                    if(type === 'staff'){
                        const total = totalRecords -1;
                        logEvent({category:Categories.staff,action:Actions.staff_bulk_upload_success,value:total});
                    }
                    else if(type === 'device'){
                        const total = totalRecords -1;
                        logEvent({category:Categories.device,action:Actions.device_bulk_upload_success,value:total});
                    }
                    else if(type === 'badge'){
                        const total = totalRecords -1;
                        logEvent({category:Categories.badge,action:Actions.badge_bulk_upload_success,value:total});
                    }
                    else if(type === 'student'){
                        const total = totalRecords -1;
                        logEvent({category:Categories.student,action:Actions.student_bulk_upload_success,value:total});
                    }
                    toast.success('Successfully Saved!', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1000,
                    });
                    setSubmitLoading(false); 
                    closeModal();
                }
            }
        } catch (error) {
            setCancelTokenSource(null)
            setSubmitLoading(false);
            setSubmitted(false);
            setProcessed(false);
            if (axios.isCancel(error)) {
                return;
            } else {
                const result = errorFunction(error);

                if (typeof result === 'boolean') {
                    setShowModal2(true);
                } else {
                    setErrorMessage(result);
                }
            }

            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    }

    const handleDownloadClick = () => {
        const csvData = [columnNames, sampleData[type]];
        let filename = type;
        if(type ==='staff'){
            filename = "responder";
            logEvent({category:Categories.staff,action:Actions.staff_download_samplefile_btn});
        }
        else if(type ==='student'){
            filename = "user";
            logEvent({category:Categories.student,action:Actions.student_download_samplefile_btn});
          }
        else if(type ==='badge'){
            logEvent({category:Categories.badge,action:Actions.badge_download_samplefile_btn});
        }
        else if(type ==='device'){
            logEvent({category:Categories.device,action:Actions.device_download_samplefile_btn});
        }
        try {
            if (type === 'device') {
                csvData.push(['device_2', '\'10000000000000000003\'', 'Panel']);
            }
            const csv = Papa.unparse(csvData);
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${filename}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);   
        } catch (error) {
            setErrorMessage("Error occured")
        }
    };

	return (
		<div
			className='modal show'
			style={{ display: 'block', position: 'initial' }}
		>
			{showModal2 ? <LogoutModal /> : null}
			<Modal className="md-font" show={true} onHide={closeModal} backdrop='static' keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title className="xlg-font">{header}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{submitLoading && <h3>{intl.bulkUploadInProgress}</h3>}
					{errorMessage && (
						<div className='col-lg-12 col-md-12 col-sm-12 col-12 btn_error mb-1'>
							{errorMessage}
						</div>
					)}
					{!submitLoading && (
						<>
							<input
								type='file'
								id='fileInput'
                                className="md-font"
								name='file'
								accept='.csv, .xls, .xlsx, .xlsm'
								ref={fileInputRef}
								style={{
									width: '100%',
									paddingTop: '1em',
									paddingBottom: '3em',
								}}
								onChange={changeHandler}
							/>

							<p
								onClick={() => handleDownloadClick()}
								className='ui form download_link'
							>
								Download Sample file
							</p>
						</>
					)}
					{processLoading && (
						<div className='d-flex justify-content-center my-2'>
							<Spinner animation="border" variant="secondary" />
						</div>
					)}
				</Modal.Body>

				{processed && (
					<Modal.Footer className='d-flex justify-content-between'>
						<div style={{ width: '57%' }}>
							<p className="md-font" style={{ color: 'red' }}>
								Closing tab/modal will stop uploading.
							</p>
						</div>
						<div className='d-flex'>
							{submitted && (
								<div className='float_right mr-2'>
									<span className='btn btn-secondary cancel-btn button-fixed'
										onClick={() => cancelRequest()}
									>
										{intl.cancelButton}
									</span>
								</div>
							)}
							<div className='float_right'>
								<div align='center'>
									<button
										className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
										type='button'
										onClick={() => submit()}
									>
										{submitLoading ? <Spinner size='sm' /> : intl.submitButton}
									</button>
								</div>
							</div>
						</div>
					</Modal.Footer>
				)}
			</Modal>
			<ToastContainer />
		</div>
	);
};

export default BulkAddModal;