import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import intl from '../../locale/en-US.json';
import apiInstance from '../../api';
import {
	getUserIdInStore,
	getSchoolIdInStore,
	getRoleInStore,
	setPhoneNumberInStore,
	getUserBlockedStore,
	removeUserBlockedInStore,
	setNotificationStatusInStore,
	setPriorityNotificationStatusInStore,
	getPriorityUserIdInStore,
	setPriorityUserIdInStore,
	removePriorityUserIdInStore,
	getNotificationStatusInStore,
} from '../../utils/apiUtils';
import { phoneCodes } from '../../utils/phoneCodes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import AlertModal from '../Modal/AlertModal';
import VerifyPhoneModal from '../Modal/VerifyPhoneModal';
import Strings from '../../locale/en-US.json';
import { maxLength } from '../../utils/apiUtils';
// import { Loader } from 'semantic-ui-react';
import { PHONENO_ERROR, LENGTH_MAX, PASSWORD_ERROR } from '../../strings';
import { errorFunction } from '../../utils/errors';
import LogoutModal from '../Modal/LogoutModal';
import { Form, Dropdown } from 'semantic-ui-react';
import { Spinner } from 'react-bootstrap';
import useInputFocus from '../../utils/customHooks/useInputFocus';
import { useRef } from 'react';
import { Actions, Categories, logEvent } from '../../analytics';
import { blockStartPolling, stopPolling, blockHandleVisibilityChange } from '../../utils/polling';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import { guessCountryCode } from '../../utils/common';

const MAX_LENGTH = 50;
const MIN_LENGTH = 3;

const AccountSettings = ({ history, updateNotificationStatus }) => {
	const priorityId = getPriorityUserIdInStore();
	const [showModal, setShowModal] = useState(false);
	const [showUnsavedModal, setShowUnsavedModal] = useState(false);
	const formChanged = useRef(false);
	const [showModal2, setShowModal2] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [priorityFirstName, setpriorityFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [priorityLastName, setpriorityLastName] = useState('');
	const [email, setEmail] = useState('');
	const [priorityEmail, setpriorityEmail] = useState('');
	const gender = 'Male';
	const priorityGender = 'Male';
	const [phoneAreaCode, setAreaPhoneCode] = useState();
	const [priorityPhoneAreaCode, setPriorityPhoneAreaCode] = useState();
	const [phoneNumber, setPhoneNumber] = useState(''); // const [email, setEmail] = React.useState("");
	const [priorityPhoneNumber, setPriorityPhoneNumber] = useState();
	const [oldPassword, setOldPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [priorityUpdate, setPriorityUpdate] = useState(false);
	const [addPriority, setAddPriority] = useState(false);
	const [unverified, setUnverified] = useState(false);
	// const [designation, setDesignation] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [errorMessage, setErrorMessage] = React.useState('');
	const [submitted, setSubmitted] = useState(false);
	const [emailSubmitted, setEmailSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [resendLoader, setResendLoader] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const [showVerifyModal, setShowVerifyModal] = useState(false);
	const [currentPhoneNumber, setCurrentPhoneNumber] = useState('');
	const [receiveNotification, setReceiveNotification] = React.useState(true);
	const [priorityReceiveNotification, setPriorityReceiveNotification] = React.useState(true);
	const [receiveNotificationOriginalVal, setReceiveNotificationOriginalVal] =
		React.useState(true);

	const role = getRoleInStore();
	const isAnyInputFocused = useInputFocus();
	let nextInHistoryRef = useRef('');
	let allowNavigationRef = useRef(false);

	const id = getSchoolIdInStore();

	const pollingIntervalRef = useRef(null);

	const resendCodes = async() =>{
        setResendLoader(true);
		try {
			let response;
			if (!emailSubmitted) {
                setEmailSubmitted(true)
				response = await apiInstance.post(`/api/v1/users/resend-verification-codes/${priorityId}`);
				setResendLoader(false);
				if (response.status === 200) {
					logEvent({category:Categories.authentication,action:Actions.resend_verification_email_success});
					setEmailSubmitted(false)
                    toast.success("Successfully sent Email and SMS codes!", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
				}
			}
			
		} catch (error) {
			setEmailSubmitted(false);
            setResendLoader(false);
			if(error) {
				let errorMsg = "Error in Verifying Email";
				console.error('------ ', error);
				if (typeof error === 'string') {
					errorMsg = error;
		     	}
				else if(error.response && error.response.data && error.response.data.statusMessage){
					errorMsg = error.response.data.statusMessage;
				 }
				 console.log(errorMsg);
		    }
 
		}
	}

	const visibilityChangeHandler = () => blockHandleVisibilityChange(pollAPI, pollingIntervalRef);

	useEffect(() => {
		
		if (getUserBlockedStore()) blockStartPolling(pollAPI, pollingIntervalRef);

		document.addEventListener('visibilitychange', visibilityChangeHandler);
		return () => {
			stopPolling(pollingIntervalRef);

			if (pollingIntervalRef.current) {
				clearTimeout(pollingIntervalRef.current);
			}
			document.removeEventListener('visibilitychange', visibilityChangeHandler);
		};
	}, []);

	const pollAPI = async () => {
		try {
			stopPolling(pollingIntervalRef);
			let response;
			if (role === 'SuperAdmin') {
				response = await apiInstance.get(`/api/v1/organizations`).catch((error) => {
					const result = errorFunction(error);
					if (typeof result === 'boolean') {
						setShowModal2(true);
					} else {
						blockStartPolling(pollAPI, pollingIntervalRef);
					}
				});
			} else {
				response = await apiInstance
					.get(`/api/v1/schools/${id}/status`)
					.catch((error) => {
						const result = errorFunction(error);
						if (typeof result === 'boolean') {
							setShowModal2(true);
						} else {
							blockStartPolling(pollAPI, pollingIntervalRef);
						}
					});
			}

			if (response.status === 200) {
				removeUserBlockedInStore();
				stopPolling(pollingIntervalRef);
			} else {
				blockStartPolling(pollAPI, pollingIntervalRef);
			}
		} catch (error) {
			blockStartPolling(pollAPI, pollingIntervalRef);
			console.log(error);
		}
	};

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
				event.preventDefault(); // Prevent default undo behavior when no input is focused
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isAnyInputFocused]);

	// const genderOptions = [
	// 	{ key: 1, text: 'Male', value: 'Male' },
	// 	{ key: 2, text: 'Female', value: 'Female' },
	// 	{ key: 3, text: 'Other', value: 'Other' },
	// ];

	useEffect(() => {
		fetchData();
		return () => {
			setLoading(false);
		};
	}, []);

	const fetchData = async () => {
		const id = getUserIdInStore();
		const priorityId = getPriorityUserIdInStore();
		try {
			setLoading(true);
			const result = await apiInstance.get(`/api/v1/users/${id}`);
			let priorityResult = null;
			if(priorityId && priorityId !== "undefined") {
				priorityResult = await apiInstance.get(`/api/v1/users/${priorityId}`);
			}
			if (result && result.data) {
				setFirstName(result.data.user.first_name);
				setLastName(result.data.user.last_name);
				setEmail(result.data.user.email);
				//setGender(result.data.user.gender);
				setPhoneNumber(result.data.user.phone_no.split('-')[1]);
				const code = phoneCodes.find(
					(phoneCode) =>
					phoneCode.value.split(' ')[0] ===
					result.data.user.phone_no.split('-')[0]
					);
					setCurrentPhoneNumber(result.data.user.phone_no);
					setReceiveNotification(result.data.user.receive_notification);
					setReceiveNotificationOriginalVal(
						result.data.user.receive_notification
						);
						setAreaPhoneCode(code.value);
						setOldPassword();
					}
					
			
			if (priorityResult && priorityResult.data) {
				setPriorityUpdate(true);
				setpriorityFirstName(priorityResult.data.user.first_name);
				setpriorityLastName(priorityResult.data.user.last_name);
				setpriorityEmail(priorityResult.data.user.email);
				//setPriorityGender(priorityResult.data.user.gender);
				setPriorityPhoneNumber(priorityResult.data.user.phone_no.split('-')[1]);
				const priorityCode = phoneCodes.find(
					(phoneCode) =>
						phoneCode.value.split(' ')[0] ===
						priorityResult.data.user.phone_no.split('-')[0]
				);
				setCurrentPhoneNumber(priorityResult.data.user.phone_no);
				setPriorityReceiveNotification(priorityResult.data.user.receive_notification);
				const verified = priorityResult.data.user.email_verification_code || priorityResult.data.user.phone_verification_code;
				setUnverified(verified);
				setPriorityPhoneAreaCode(priorityCode.value);
			}
			else {
				let detectedCountryCode = guessCountryCode();
				detectedCountryCode && setPriorityPhoneAreaCode(detectedCountryCode);
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);

			console.error(error);

			const result = errorFunction(error);

			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				setErrorMessage(result);
			}
		}
	};
	const showError = (result) => {
		console.log(result)
		setErrorMessage(result);
		if (result) {
			window.scrollTo(0, 0);
		}
	};

	const handleFormChange = async (event) => {
		checkActivePriorityForm()
		const { type } = event.target;
		if (type === 'checkbox') {
			return;
		}
		event.preventDefault();
		if (event.nativeEvent && event.nativeEvent.inputType) {
			formChanged.current = true;
		}
	};
	
	const checkActivePriorityForm = () => {
		if(priorityFirstName || priorityLastName || priorityEmail || priorityPhoneNumber) {
			return true
		}
		return false;
	};

	useEffect(() => {
		const handleBeforeUnload = (e) => {
			if (!checkUnsavedChanges()) {
				e.preventDefault();
				e.returnValue =
					'You have unsaved changes. Are you sure you want to leave this page?';
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	const closeUnsavedModal = () => setShowUnsavedModal(false);

	const handleReceiveNotificationSwitchChange = () => {
		setReceiveNotification(!receiveNotification);
		formChanged.current = true;
	};
	const handlePriorityReceiveNotificationSwitchChange = () => {
		setPriorityReceiveNotification(!priorityReceiveNotification);
		formChanged.current = true;
	};

	const checkUnsavedChanges = () => {
		if (!formChanged.current) {
			return true;
		}
		return false;
	};

	const navigationBlockCheck = (nextLocation) => {
		nextInHistoryRef.current = nextLocation && nextLocation.pathname;
		if (allowNavigationRef.current) {
			return true;
		}
		if (checkUnsavedChanges()) {
			return true;
		} else {
			setShowUnsavedModal(true);
			return false;
		}
	};

	const handleNavigationConfirmation = () => {
		setShowUnsavedModal(false);
		allowNavigationRef.current = true;
		history.push(nextInHistoryRef.current);
	};

	useEffect(() => {
		const unblock = history.block(navigationBlockCheck);

		return () => {
			unblock();
		};
	}, [history]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!formChanged.current) {
			toast.error('No Changes to Save!', {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
			});
			return;
		}
		// const id = getUserIdInStore();
		logEvent({
			category: Categories.accountSettings,
			action: Actions.accountSettings_submit_btn,
			role: getRoleInStore(),
		});
		setErrorMessage('');
		if (
			firstName.trim().length < MIN_LENGTH ||
			firstName.trim().length > MAX_LENGTH
		) {
			showError(Strings.firstName + ' : ' + LENGTH_MAX);
			return;
		}
		

		if (
			lastName.trim().length < MIN_LENGTH ||
			lastName.trim().length > MAX_LENGTH
		) {
			showError(Strings.lastName + ' : ' + LENGTH_MAX);
			return;
		}
		

		const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		if (!email.match(emailRegex)) {
			showError('Invalid Email format');
			return false;
		}
		let atIndex = email.indexOf('@');
		let lastCharBeforeAt = email[atIndex - 1];
		let lastCharBeforeAtRegex = /^[a-zA-Z0-9]$/;
		if (!lastCharBeforeAt.match(lastCharBeforeAtRegex)) {
			showError('Invalid Email format');
			return false;
		}

		

		// if (gender === '') {
		// 	showError("Gender can't be empty");
		// 	return;
		// }
		

		if (
			newPassword !== '' &&
			!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,40}$/.test(newPassword)
		) {
			showError(PASSWORD_ERROR);
			return;
		}
		if (!phoneAreaCode) {
			showError(PHONENO_ERROR);
			return;
		}
		if (
			phoneAreaCode.split(' ')[0].split('').slice(1).join('').length +
				phoneNumber.length <
				8 ||
			phoneAreaCode.split(' ')[0].split('').slice(1).join('').length +
				phoneNumber.length >
				15
		) {
			showError(PHONENO_ERROR);
			return;
		}
		if (newPassword !== confirmPassword) {
			showError('Passwords do not match');
			return;
		}
		let priorityRecieveNotificationSentToServer = priorityReceiveNotification;
		let priorityData = null;
		const priorityForm = checkActivePriorityForm();
		if(priorityUpdate || priorityForm){
			if (
				priorityFirstName.trim().length < MIN_LENGTH ||
				priorityFirstName.trim().length > MAX_LENGTH
			) {
				showError(Strings.priorityFirstName + ' : ' + LENGTH_MAX);
				return;
			}
			if (
				priorityLastName.trim().length < MIN_LENGTH ||
				priorityLastName.trim().length > MAX_LENGTH
			) {
				showError(Strings.priorityLastName + ' : ' + LENGTH_MAX);
				return;
			}
			if (!priorityEmail.match(emailRegex)) {
				showError('Priority Contact Invalid Email format');
				return false;
			}
			atIndex = priorityEmail.indexOf('@');
			lastCharBeforeAt = priorityEmail[atIndex - 1];
			lastCharBeforeAtRegex = /^[a-zA-Z0-9]$/;
			if (!lastCharBeforeAt.match(lastCharBeforeAtRegex)) {
				showError('Invalid Priority Email format');
				return false;
			}

			// if (priorityGender === '') {
			// 	showError("Priority Gender can't be empty");
			// 	return;
			// }
			if (!priorityPhoneAreaCode) {
				showError("Priority Contact " + PHONENO_ERROR);
				return;
			}
			if (!priorityPhoneNumber) {
				showError("Priority Contact " + PHONENO_ERROR);
				return;
			}
			if (
				priorityPhoneAreaCode.split(' ')[0].split('').slice(1).join('').length +
				priorityPhoneNumber.length <
					8 ||
				priorityPhoneAreaCode.split(' ')[0].split('').slice(1).join('').length +
					priorityPhoneNumber.length >
					15
			) {
				showError("Priority Contact " + PHONENO_ERROR);
				return;
			}
			if (priorityUpdate) {
				priorityData = {
					first_name: priorityFirstName.trim(),
					last_name: priorityLastName.trim(),
					email: priorityEmail,
					receive_notification: priorityReceiveNotification,
					gender: priorityGender,
					role: 'PriorityContact',
					phone_no: `${priorityPhoneAreaCode.split(' ')[0]}-${priorityPhoneNumber}`,
				};
			}
			else {
				priorityData = [{
					first_name: priorityFirstName.trim(), 
					last_name: priorityLastName.trim(),
					email: priorityEmail,
					gender: priorityGender,
					receive_notification: priorityReceiveNotification,
					role: 'PriorityContact',
					phone_no: `${priorityPhoneAreaCode.split(' ')[0]}-${priorityPhoneNumber}`,
				}];
			}
			setpriorityFirstName(priorityFirstName.trim());
			setpriorityLastName(priorityLastName.trim());
		}
		const data = {
			first_name: firstName.trim(),
			last_name: lastName.trim(),
			email: email,
			gender: gender,
			role: getRoleInStore(),
			receive_notification: receiveNotification,
			phone_no: `${phoneAreaCode.split(' ')[0]}-${phoneNumber}`,
			priorityData: priorityData,
		};
		setFirstName(firstName.trim());
		setLastName(lastName.trim());

		if (oldPassword && newPassword && confirmPassword) {
			setSubmitted(false);
			data.password = oldPassword;
			data.new_password = newPassword;
		}

		try {
			let result;
			if (!submitted) {
				setSubmitted(true);
				setLoading2(true);
				setSubmitted(true);
				result = await apiInstance.put('/api/v1/auth/user', data);
			}
			// console.log('ACCOUNT SETTINGS: ',result);

			if (result && result.status === 200) {
				allowNavigationRef.current = true;
				setLoading2(false);
				result.data.user.phone_no &&
				setPhoneNumberInStore(result.data.user.phone_no);
				if (result.data.user.receive_notification !== undefined) {
					setNotificationStatusInStore(result.data.user.receive_notification);
				}
				if (
					receiveNotificationOriginalVal !==
					result.data.user.receive_notification
				) {
					logEvent({
						category: Categories.accountSettings,
						action: Actions.accountSettings_notification,
						value: result.data.user.receive_notification,
					});
				}
				logEvent({
					category: Categories.accountSettings,
					action: Actions.accountSettings_submit_success,
				});
				
				toast.success('Successfully Saved!', {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 2000,
				});
				if(result.data.user.priority_contact_id) {
					setPriorityUserIdInStore(result.data.user.priority_contact_id);
					setPriorityNotificationStatusInStore(priorityRecieveNotificationSentToServer);
					updateNotificationStatus(priorityRecieveNotificationSentToServer);
				}
				const blocked = getUserBlockedStore()
					if ((blocked)) {
					setTimeout(() => {
						history.goBack();
					}, 2000);
				} else {
					setTimeout(() => {
						role === 'SuperAdmin'
							? history.push('/organizations')
							: history.push('/dashboard');
					}, 2000);
				}
			}
		} catch (error) {
			setLoading2(false);
			setSubmitted(false);
			const result = errorFunction(error);

			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				showError(result);
			}
		}
	};

	const closeModal = () => setShowModal(false);
	const closePhoneModal = () => {
		setShowVerifyModal(false);
		fetchData();
	};

	const deletePriorityContact = async () => {
		try {
			//const result = await apiInstance.delete(`/api/v1/schools/${id}`);
			const result = await apiInstance.delete(`/api/v1/users/${priorityId}`);
			if (result && result.status === 200) {
				removePriorityUserIdInStore();
				updateNotificationStatus(getNotificationStatusInStore());
				toast.success('Successfully Deleted!', {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 2000,
				});
				setTimeout(() => {
					history.push('/');
				}, 2000);
				
			}
		} catch (error) {
			setLoading(false);
			setSubmitted(false);
			const result = errorFunction(error);

			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				showError(result);
			}
		}
		setShowModal(false);
	};

	return (
		<div>
			<Breadcrumb
				links={[{ link: '/settings', title: Strings.accountSettings }]}
			/>
			{showVerifyModal && (
				<VerifyPhoneModal
					closeModal={closePhoneModal}
					currentphno={currentPhoneNumber}
				/>
			)}

			<div className='card-header card_headertitle'>
				<div className='card-title card_head'>
					<h4 className='card_head CardTitle text-uppercase component-heading  '>
						{Strings.accountSettings}
					</h4>
				</div>
			</div>
			{errorMessage && (
				<div className='col-lg-12 col-md-12 col-sm-12 col-12 btn_error'>
					{errorMessage}
				</div>
			)}

			{loading ? (
				<div className="d-flex justify-content-center align-items-center">
					<Spinner animation="border" variant="secondary" />
				</div>
			) : (
				<div className='card-body' style={{ background: '#fff' }}>
					<Form onSubmit={handleSubmit} onChange={handleFormChange} action=''>
						<div className='row'>
							<div className='col-lg-6 col-md-12 col-sm-12 col-12'>
							<div className='col-12 mb-4'>
								<Form.Field className='double-height'>
									<h4 style={{ fontWeight: 600, color: 'black' }}>
										{Strings.adminSettings}
									</h4>
								</Form.Field>
								</div>
								<div className='col-lg-12 col-md-12 col-sm-12 col-12 mb-3'>
									<Form.Field className='double-height'>
										<label>
											{Strings.firstName}
											<span className='font_red'>*</span>
										</label>
										<input
											type='text'
											// className='form-control'
											name='BeaconMac'
											value={firstName}
											onChange={(e) =>
												setFirstName(maxLength(e.target.value, 50))
											}
											required
										/>
									</Form.Field>
								</div>

								<div className='col-lg-12 col-md-12 col-sm-12 col-12 mb-3'>
									<Form.Field className='double-height'>
										<label>
											{Strings.lastName}
											<span className='font_red'>*</span>
										</label>
										<input
											type='text'
											// className='form-control'
											name='BeaconMac'
											value={lastName}
											onChange={(e) =>
												setLastName(maxLength(e.target.value, 50))
											}
											required
										/>
									</Form.Field>
								</div>

								<div className='col-lg-12 col-md-12 col-sm-12 col-12 mb-3'>
									<Form.Field className='double-height'>
										<label>
											{Strings.email}
											<span className='font_red'>*</span>
											<span style={{ fontSize: '10px', fontWeight: '150', marginLeft: '5px' }}>
											{ role !== "SuperAdmin" ?
											(intl.emailReasonMessage) : ""}
											</span>
										</label>
										<input
											id='email'
											name='email'
											className='form-control'
											placeholder={Strings.email}
											type='email'
											value={email}
											onChange={(e) => setEmail(maxLength(e.target.value, 50))}
											required
										/>
									</Form.Field>
								</div>

								{/* <div className='col-lg-12 col-md-12 col-sm-12 col-12 mb-3'>
									<Form.Field className='double-height'>
										<div className='d-flex flex-column'>
										<label>
											{Strings.gender}
											<span className='font_red'>*</span>
										</label>
										<span className='GenderGroup'>
											<Dropdown
												options={genderOptions}
												required
												selection
												placeholder='Gender'
												value={gender}
												onChange={(e, { value }) => {
													formChanged.current = true;
													setGender(value);
												}}
											/>
										</span>
										</div>
									</Form.Field>
								</div> */}

								<div className='col-12 mb-3'>
										<label>
											{Strings.phoneNo}
											<span className='font_red'>*</span>
											<span style={{ fontSize: '10px', fontWeight: '150', marginLeft: '5px' }}>
												{role !== "SuperAdmin" ?
												(intl.smsReasonMessage):""}
											</span>
										</label>

										<div className='d-flex'>
											<div className=''>
												<Dropdown
													options={phoneCodes}
													selection
													placeholder='Code'
													search
													style={{ minWidth: '10em' }}
													value={phoneAreaCode}
													onChange={(e, { value }) => {
														formChanged.current = true;
														setAreaPhoneCode(value);
													}}
												/>
											</div>
											<div className='col-lg-5 col-md-5 col-sm-9 col-9'>
												<input
													type='text'
													className='form-control'
													style={{ width: '95%' }}
													name='phoneNumber'
													onKeyDown={(event) => {
														const allowedKeys = [
															'Backspace',
															'Delete',
															'ArrowLeft',
															'ArrowRight',
															'Tab',
														];
														// Allow Ctrl + V (Control key + V key)
														if (
															event.key === 'v' &&
															(event.ctrlKey || event.metaKey)
														) {
															// Continue with the default paste behavior
															return;
														}

														if (
															!allowedKeys.includes(event.key) &&
															(isNaN(Number(event.key)) || event.key === ' ')
														) {
															event.preventDefault();
														}
													}}
													value={phoneNumber}
													onChange={(event) =>
														setPhoneNumber(maxLength(event.target.value, 20))
													}
													required
												/>
											</div>
										</div>
								</div>

								<div className='col-lg-12 col-md-12 col-sm-12 col-12 mb-3'>
									<Form.Field className='double-height'>
										<label>
											{Strings.oldPassword}
											<span className='font_red'>*</span>
										</label>
										<input
											type={showPassword ? 'text' : 'password'}
											className='form-control'
											name='BeaconMac'
											value={oldPassword || ''}
											onChange={(e) =>
												setOldPassword(maxLength(e.target.value, 40))
											}
										/>
									</Form.Field>
								</div>

								<div className='col-lg-12 col-md-12 col-sm-12 col-12 mb-3'>
									<Form.Field className='double-height'>
										<label>
											{Strings.newPassword}
											<span className='font_red'>*</span>
										</label>
										<input
											type={showPassword ? 'text' : 'password'}
											className='form-control'
											value={newPassword}
											onChange={(e) =>
												setNewPassword(maxLength(e.target.value, 40))
											}
										/>
									</Form.Field>
								</div>

								<div className='col-lg-12 col-md-12 col-sm-12 col-12 mb-3'>
									<Form.Field className='double-height'>
										<label>
											{Strings.confirmPassword}
											<span className='font_red'>*</span>
										</label>
										<input
											type={showPassword ? 'text' : 'password'}
											className='form-control'
											name='BeaconMac'
											value={confirmPassword}
											onChange={(e) =>
												setConfirmPassword(maxLength(e.target.value, 40))
											}
										/>
									</Form.Field>
								</div>
							<div className='col-lg-12 col-md-12 col-sm-12 col-12 ml-3 mt-2'>
								<Form.Field className='double-height'>
									<input
										type='checkbox'
										id='vehicle1'
										checked={showPassword}
										onChange={() => setShowPassword(!showPassword)}
									/>
									<label htmlFor='vehicle1'>
										<p className='ml-2'>{Strings.showPassword}</p>
									</label>
									<br />
								</Form.Field>
							</div>
							{ role !== "SuperAdmin" ?
							<div className='col-lg-12 col-md-12 col-sm-12 col-12 mt-2 ml-3'>
									<Form.Field className='double-height'>
										<label style={{ minWidth: '11em' }} className='form-label'>
											{intl.studentsStaffNotification}
										</label>
									</Form.Field>
									<div className='switch'>
										<label style={{ minWidth: '11em' }} className='form-label'>
											{intl.receiveNotification}
										</label>
										<label className='form-label'>
											<input
												type='checkbox'
												className='toggle-input'
												onChange={handleReceiveNotificationSwitchChange}
												checked={receiveNotification}
											/>
											<span className='lever'></span>
										</label>
									</div>
								</div>
								:""}
							</div>
							{ role !== 'SuperAdmin' ?
							<div className='col-lg-6 col-md-12 col-sm-12 col-12 settings-divider'>
							{
								(!priorityUpdate && addPriority) ? 
								<Form.Field className='d-flex justify-content-between'>
									<h4 style={{ fontWeight: 600, color: 'black' }}>
										{Strings.primaryPriorityContactInformation}
									</h4>
									<span	title='Add Alternate contact'
										className='btn AddButton btn-sm bulk_buttons float_right'
										onClick={() =>
											setAddPriority(true)
										}
									>
										<i>
											<FaPlus />
										</i>{'  '}
										{intl.addAlternate}
									</span>
								</Form.Field>:
								<div>
								<div className='col-12 mb-4'>
								<Form.Field className='d-flex justify-content-between'>
									<h4 className='d-flex' style={{ fontWeight: 600, color: 'black' }}>
										{Strings.primaryPriorityContactInformation}
										{priorityUpdate ? "" :
										" (Not Added)"
										}
									</h4>
									{
									!priorityUpdate ? ""
									:
									<span	title='Delete'
										className='btn AddButton btn-sm bulk_buttons float_right'
										onClick={() =>
											setShowModal(true)
										}
									>
										<i>
											<FaTrashAlt />
										</i>{'  '}
										{intl.deleteButton}
									</span>
								}
								</Form.Field>
								</div>
									
								<div className='col-12 my-3'>
									<Form.Field className='double-height'>
										<label>
											{Strings.firstName}
											<span className='font_red'>*</span>
										</label>
										<input
											placeholder={Strings.firstName}
											type='text'
											value={priorityFirstName}
											onChange={(e) => setpriorityFirstName(maxLength(e.target.value, 50))}
											
										/>
									</Form.Field>
								</div>
								<div className='col-12 my-3'>
									<Form.Field className='double-height'>
										<label>
											{Strings.lastName}
											<span className='font_red'>*</span>
										</label>
										<input
											placeholder={Strings.lastName}
											type='text'
											value={priorityLastName}
											onChange={(e) => setpriorityLastName(maxLength(e.target.value, 50))}
											
										/>
									</Form.Field>
								</div>
								<div style={!unverified?{}:{backgroundColor:'#f2f2f2'}}>
								{ !unverified ? "":
												<div style={{width:'100%'}} >
												<div  className='cursor-pointer float-right mt-2 mb-2 mr-2'id='loginpage-links'
												>
													{resendLoader ?<Spinner size='sm'/>: 
													<p className=' login_contact float-right blueText'
													onClick={() => resendCodes()}
														style={{cursor:"pointer", fontWeight:300}}
													>
													
													{Strings.resendCodes}
													</p>
												}
												</div>
												<hr style={{ width: '100%' }} />
											</div>
										}
								<div className='col-12 my-3'>
									<Form.Field className='double-height'>
										<label  className='d-flex'>
											{Strings.email}
											<span className='font_red'>*</span>
											<span style={{ fontSize: '10px', fontWeight: '150', marginLeft: '5px' }}>
												({intl.emailReasonMessage})
											</span>
										</label>
										<input
											placeholder={Strings.email}
											type='text'
											value={priorityEmail}
											onChange={(e) => setpriorityEmail(maxLength(e.target.value, 50))}
											
										/>
									</Form.Field>
								</div>
								
								{/* <div className='col-lg-12 col-md-12 col-sm-12 col-12 mb-3'>
									<Form.Field className='double-height'>
										<div className='d-flex flex-column'>
										<label>
											{Strings.gender}
											<span className='font_red'>*</span>
										</label>
										<span className='GenderGroup'>
											<Dropdown
												options={genderOptions}
												
												selection
												placeholder='Gender'
												value={priorityGender}
												onChange={(e, { value }) => {
													formChanged.current = true;
													setPriorityGender(value);
												}}
											/>
										</span>
										</div>
									</Form.Field>
								</div> */}
								
								<div className='col-12 mt-3'>
										<label>
											{Strings.phoneNo}
											<span className='font_red'>*</span>
											<span style={{ fontSize: '10px', fontWeight: '150', marginLeft: '5px' }}>
												({intl.smsReasonMessage})
											</span>
										</label>

										<div className='d-flex'>
											<div className='form-group '>
												<Dropdown
													options={phoneCodes}
													selection
													placeholder='Code'
													search
													style={{ minWidth: '10em' }}
													value={priorityPhoneAreaCode}
													onChange={(e, { value }) => {
														formChanged.current = true;
														setPriorityPhoneAreaCode(value);
													}}
												/>
											</div>
											<div className='form-group col-lg-5 col-md-5 col-sm-9 col-9'>
												<input
													type='text'
													className='form-control'
													style={{ width: '95%' }}
													name='phoneNumber'
													onKeyDown={(event) => {
														const allowedKeys = [
															'Backspace',
															'Delete',
															'ArrowLeft',
															'ArrowRight',
															'Tab',
														];
														// Allow Ctrl + V (Control key + V key)
														if (
															event.key === 'v' &&
															(event.ctrlKey || event.metaKey)
														) {
															// Continue with the default paste behavior
															return;
														}

														if (
															!allowedKeys.includes(event.key) &&
															(isNaN(Number(event.key)) || event.key === ' ')
														) {
															event.preventDefault();
														}
													}}
													value={priorityPhoneNumber}
													onChange={(event) =>
														setPriorityPhoneNumber(maxLength(event.target.value, 20))
													}
													
												/>
											</div>
											</div>
										</div>
									</div>
									<div className='col-lg-12 col-md-12 col-sm-12 col-12 mt-2 ml-3'>
										<Form.Field className='double-height'>
											<label style={{ minWidth: '11em' }} className='form-label'>
												{intl.studentsStaffNotification}
											</label>
										</Form.Field>
										<div className='switch'>
											<label style={{ minWidth: '11em' }} className='form-label'>
												{intl.receiveNotification}
											</label>
											<label className='form-label'>
												<input
													type='checkbox'
													className='toggle-input'
													onChange={handlePriorityReceiveNotificationSwitchChange}
													checked={priorityReceiveNotification}
												/>
												<span className='lever'></span>
											</label>
										</div>
									</div>
								</div>
								}
							</div>
						:""}
						</div>

						<div className='row'>
							<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
								<div className='float_right mr-3'>
									<div align='center'>
										<button
											className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed'
											type='submit'
										>
											{loading2 ? <Spinner size='sm'/> : Strings.submitButton}
										</button>
									</div>
								</div>

								<div className='float_right mr-3'>
									<span
										className='btn btn-secondary cancel-btn button-fixed'
										onClick={(event) => {
											event.preventDefault();
											history.goBack();
											logEvent({
												category: Categories.accountSettings,
												action: Actions.accountSettings_cancel_btn,
											});
										}}
									>
										{Strings.cancelButton}
									</span>
								</div>
							</div>
						</div>
					</Form>
					<div>
						{showModal ? (
							<AlertModal
								closeModal={closeModal}
								header={Strings.deleteHeader}
								body={Strings.deletePriorityContactAlertMsg}
								handlerFunction={deletePriorityContact}
							/>
						) : null}
						{showModal2 ? <LogoutModal /> : null}
						{showUnsavedModal ? (
							<AlertModal
								closeModal={closeUnsavedModal}
								header={Strings.unsavedChanges}
								body={Strings.unsavedChangesText}
								handlerFunction={handleNavigationConfirmation}
							/>
						) : null}
					</div>
				</div>
			)}
			<ToastContainer />
		</div>
	);
};

export default withRouter(AccountSettings);
