import { Form, Image } from 'semantic-ui-react';

import { ToastContainer } from 'react-toastify';
import AllImages from '../../assets/images/images.js';
import intl from '../../locale/en-US.json';
import React, { useState } from 'react';
import apiInstance from '../../api';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { EMAIL_SENT } from '../../strings';
import { maxLength } from '../../utils/apiUtils';
import { Spinner } from 'react-bootstrap';
import Strings from '../../locale/en-US.json';
import useInputFocus from '../../utils/customHooks/useInputFocus';
import { withRouter } from 'react-router-dom';
import { Actions, Categories, logEvent } from '../../analytics';
import { version } from '../../../package.json';

const ForgotPassword = ({ history }) => {
	const [email, setEmail] = React.useState('');
	const [loading, setLoading] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const isAnyInputFocused = useInputFocus();
	const searchParams = new URLSearchParams(window.location.search);
	const emailFromUrl = searchParams.get('email') || null;

	React.useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
				event.preventDefault(); // Prevent default undo behavior when no input is focused
			}
		};

		document.addEventListener('keydown', handleKeyDown);
		if (emailFromUrl) {
			setEmail(emailFromUrl);
		}

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isAnyInputFocused]);

	const handleNavigation = (path) => {
		history.push(path);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		try {
			let response;
			if (!isSubmitted) {
				setLoading(true);
				setIsSubmitted(true);
				logEvent({
					category: Categories.authentication,
					action: Actions.forgot_pwd_submitted_btn,
				});
				response = await apiInstance.post('/api/v1/auth/reset-password', {
					email: email,
				});
				if (response.status === 200) {
					logEvent({
						category: Categories.authentication,
						action: Actions.forgot_pwd_submitted_success,
					});
					setLoading(false);
					toast.success(EMAIL_SENT, {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 2000, // Toast message will be shown for 2 seconds
					});
					setTimeout(() => {
						history.push('/login');
					}, 2000);
				}
			}
		} catch (error) {
			setIsSubmitted(false);
			setLoading(false);

			toast.success(EMAIL_SENT, {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000, // Toast message will be shown for 2 seconds
			});
			setTimeout(() => {
				history.push('/login');
			}, 2000);
		}
	};

	return (
		<>
			<div className='page-wrapper d-block'>
				<div className='ui container'>
					<div className='row'>
						<div className='col-lg-4 col-md-2 col-2'></div>
						<div className='col-lg-4 col-md-8 col-8'>
							<div className='logo_top' align='center'>
								<Image
									src={AllImages['logo']}
									alt='Logo'
									className='logo_class'
								/>
								<p>v {version}</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-2 col-2'></div>
					</div>
				</div>
				<div id='root'>
					<div className='authentications'>
						<div
							className='auth-wrapper align-items-center d-flex'
							style={{
								backgroundImage: 'url()',
								backgroundRepeat: 'no-repeat',
								backgroundPosition: 'center center',
							}}
						>
							<div className='ui container'>
								<div className='row'>
									<div className='col-lg-3 col-md-1 col-1'></div>
									<div className='col-lg-6 col-md-10 col-10'>
										<div className='border card'>
											<div className='card-body'>
												<div className='p-4'>
													<h3 className='font-medium mb-3' align='center'>
														{intl.forgotPassword}
													</h3>
													<Form onSubmit={handleSubmit}>
														<div className='error mb-2'></div>
														<div className='mb-2' style={{ display: 'flex' }}>
															<span className='themify-container'>
																<img
																	src={AllImages['user']}
																	alt='Key Icon'
																	className='themify-icon-medium'
																/>
															</span>
															<input
																placeholder={Strings.email}
																type='email'
																value={email}
																onChange={(e) =>
																	setEmail(maxLength(e.target.value, 50))
																}
																required
																className='themify-input border_right'
															/>
														</div>
														<p className='greyText'>
															{Strings.forgetPasswordEmailNote}
														</p>

														<span>{intl.forgotPassNote}</span>
														<div className='error mb-2'></div>

														<div className='error mb-2'></div>

														<div align='center'>
															<button className='btn btn-login btn-lg btn-block loginloader LoginBtnLoader mb-2 '>
																{loading ? (
																	<Spinner
																		size='sm'
																		animation='border'
																		variant='secondary'
																	/>
																) : (
																	Strings.submitButton
																)}
															</button>
														</div>
														<div className='row'>
															<div
																className='col-lg-12 col-md-12 col-sm-12 col-12 '
															>
																<span className='blackText hover-pointer'>
																	<span
																		onClick={() => {
																			handleNavigation('/login');
																			logEvent({
																				category: Categories.authentication,
																				action:
																					Actions.forgot_pwd_back_to_signin,
																			});
																		}}
																	>
																		{intl.backTologin}
																	</span>
																</span>
															</div>
														</div>
													</Form>
												</div>
											</div>
										</div>
										<div className='col-lg-3 col-md-2 col-2'></div>
									</div>
									<div className='col-lg-3 col-md-1 col-1'></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ToastContainer />
			</div>
		</>
	);
};

export default withRouter(ForgotPassword);
